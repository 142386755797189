import * as React from 'react';
import {useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Paper    from '@mui/material/Paper';
import Input    from '../common/Input';
import BtnSubmit from '../common/BtnSubmit';
import StateContext from '../../helpers/ContextState';
import Loading from '../common/Loading01';
import Button from '@mui/material/Button';
import {init,set,uri} from '../../helpers/fninputs';
import Select from '../common/Select';
import { useLocation } from "react-router-dom";


const btnSubmitId    =   'btnSubmitId'
let getInit

const App=()=>{
  const location                              =   useLocation();
  //const navigate                              =   useNavigate();
  const context                               =   React.useContext(StateContext);
  const [loading, setLoading]                 =   React.useState(true);
  const [inputs, setInputs]                   =   React.useState({});
  const [users, setUsers]                     =   React.useState([]);


  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{

    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data && response.data.contact) {
        setInputs(response.data.contact)
      }
      if (response.data && response.data.users) {
        setUsers(response.data.users)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmit=(e)=>{
    e.preventDefault()
    context.post(uri(location.pathname)+"/contacts",{  ...inputs},false,true).then((response)=>{
      if (response && response.data) {
        window.history.go(-1);
      }
    })
  }

  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid  item xs={11} sm={11} md={11} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>
                      <Paper sx={{ p: 2, }}>
                        <Grid container spacing={0}>
                          <Grid  item xs={3}>
                            <Input  margin="normal"
                                    required
                                    fullWidth
                                    type="text"
                                    defaultValue={inputs.departament}
                                    label="Departamento o cargo"
                                    name="departament"
                                    onChange={set}
                                    />
                          </Grid>
                          <Grid  item xs={3}>
                            <Input  margin="normal"
                                    required
                                    fullWidth
                                    type="text"
                                    defaultValue={inputs.phone}
                                    label="Celular"
                                    name="phone"
                                    onChange={set}
                                    />
                          </Grid>
                          <Grid  item xs={3}>
                            <Input  margin="normal"
                                    required
                                    fullWidth
                                    type="email"
                                    defaultValue={inputs.email}
                                    label="Email"
                                    name="email"
                                    onChange={set}
                                    />
                          </Grid>
                          <Grid  item xs={12} md={2}>
                            <Select data={users}
                                    name="user_id"
                                    byId={"id"}
                                    defaultValue={inputs.user_id}
                                    placeholder='Usuario'
                                    inputs={inputs}
                                    setInputs={set}
                                    label="Usuario"
                                    value={inputs.user_id} />
                          </Grid>
                          <Grid  item xs={12}>
                            <Button variant="contained"
                                    type="submit"
                                    sx={{ ml: 1 }}>
                                    Guardar
                            </Button>
                            <Button type="button"
                                    onClick={()=>window.history.go(-1)}
                                    variant="outlined"
                                    sx={{ ml: 1 }}>
                              Cancelar
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
