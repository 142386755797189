import React from 'react';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material'
import { NavLink } from "react-router-dom";
import img1 from '../../assets/images/aplicaciones-web.webp';
import img4 from '../../assets/images/desarrollo-a-la-medida.png'
import img6 from '../../assets/images/pwa1.png'
import transparent from '../../assets/images/transparent.png'
import FormContact from '../common/FormContact';

const App=()=>{

  React.useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }, [])

  return  <>
            <Grid container justifyContent="center"  spacing={2} sx={{mb:0}} className="bg-white pwa">
              <Grid item xs={12} md={6}  sx={{ mt: {
                                              xs:10,
                                              md:14
                                            },
                                      }}

                >
                <Typography component="h2" className="title-banner text-center">
                  Desarrollo de <b>Software</b> a la medida de tus necesidades
                </Typography>
                <Typography component="p" className="text-center p-2">
                  Que te permite hacer las cosas más fáciles e innovadoras para que logres tus objetivos.
                </Typography>
                <Grid className="text-center">
                  <Button  component={NavLink} variant="contained" className={"mt-2"} to="/contact/buenos-dias,-podrían-cotizar-mi-software-a-la-medida">
                    Quiero cotizar mi Software
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}  sx={{ mt: {
                                              xs:10,
                                              md:14
                                            },
                                      }} >
                <img src={img1} alt="" className="img-fluid"/>
              </Grid>
              <Grid item xs={12}>
                <Grid className="separator">
                  <img src={transparent} alt={"programandoweb"} className="img-fluid"/>
                </Grid>
              </Grid>
              <Grid item xs={12} className="content-primary">
                <Typography component="h4" className="text-center text-white h4 mb-2 uppercase">
                  <b>Software</b> a medida
                </Typography>
                <Grid></Grid>
                <Grid container justifyContent="center"  spacing={2} sx={{mb:0}}>
                  <Grid item xs={12} md={4} className="text-center p-2">
                    <CardMedia
                      component="img"
                      image={img4}
                      alt="programandoweb"
                      title="Aplicacione en React JS"
                    />
                    <Typography component="h4" className="text-center text-white h5 mb-2">

                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={1}>
                  </Grid>

                  <Grid item xs={12} md={3} className="text-left p-2">
                    <Typography component="h1" className="text-center text-white mb-2 uppercase roboto">
                      <b>Ventajas</b>
                    </Typography>
                    <Typography component="h4" className="text-left text-white h5 mb-2">
                      <ul>
                        <li>
                          Plataforma de análisis de base de datos
                        </li>
                        <li>
                          Identifica que personas/empresas componen tu base de datos
                        </li>
                        <li>
                          Permite conocer la calidad de la información
                        </li>
                        <li>
                          Mayor aprovechamiento de tus datos
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid className="separator2">
                  <img src={transparent} alt={"programandoweb"} className="img-fluid"/>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography component="h4" className="text-center h4 mb-2 uppercase">
                  Nuestro proceso
                </Typography>
                <Typography component="p" className="text-center">
                  Comparte tus ideas, requisitos y expectativas con nosotros. Estudiaremos tu proyecto sin ningún costo.
                </Typography>
              </Grid>

              <Grid container justifyContent="center"  spacing={0} sx={{mb:0}}>


                <Grid item xs={12} md={2} className="text-center p-2">
                  <CardMedia
                    component="img"
                    image={img6}
                    alt="programandoweb"
                    title="PWA"
                  />

                </Grid>

                <Grid item xs={12} md={4} className="p-2">
                  <Grid>
                    <Typography component="h1" className="h4 uppercase">
                        <b>Manos a la obra </b>
                    </Typography>
                    <Typography component="p" className="mb-2">
                     Revisamos e investigamos a fondo tus requisitos, ponemos a prueba tu idea y hacemos una revisión profunda del código (si aplica), luego identificamos las mejores soluciones tecnológicas posibles para tu proyecto.
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography component="h1" className="h4 uppercase">
                        <b>Lo que obtienes:</b>
                    </Typography>
                    <Typography component="p" className="mb-2">
                      <ul>
                        <li>
                          Alcance detallado del proyecto a través de historias de usuarios.
                        </li>
                        <li>
                          Estimaciones de costos y tiempos.
                        </li>
                        <li>
                          Análisis y asesoramiento profesional sobre tu proyecto.
                        </li>
                        <li>
                          Sugerencias sobre herramientas, métodos y soluciones necesarias.
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="text-center p-2">
                  <FormContact/>
                </Grid>
              </Grid>
            </Grid>
          </>
}
export default App;
