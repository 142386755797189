import React from 'react';
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
// import Card from '@mui/material/Card';
// import CardMedia from '@mui/material/CardMedia';
// import StateContext from '../../helpers/ContextState';
import Button   from '@mui/material/Button';

// let getInit
//
// const elementsLoading   =   [1,2,3]

const App=({data,xs,md})=>{

  // const context               =   React.useContext(StateContext);
  // const [select,setSelect]    =   React.useState(0)
  // const [loading, setLoading] =   React.useState(true);

  // getInit=()=>{
  //   context.get(false,{...context.user},false,false).then((response)=>{
  //     if (response.data) {
  //       console.log(response.data);
  //     }
  //     setLoading(false)
  //   })
  // }

  return  <Grid container justifyContent="center">
            <Grid item xs={12} className="bg-gray" sx={{p:2}}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={8} className="bg-gray" sx={{pt:5,pb:5}}>
                  <Typography component="h1" className="text-white cta-title" sx={{mb:2}}>Lorem ipsum. Lorem ipsum.Lorem ipsum.Lorem ipsum.</Typography>
                  <Button variant={"contained"} color="danger">CTA</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
