import React from 'react';
import Grid from '@mui/material/Grid';
//import Carousel    from './components/Carousel2';
import StateContext from '../../helpers/ContextState';
import Typography from '@mui/material/Typography';
import Loading from '../common/Loading01';
import { useLocation } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

let getInit=false

const App=()=>{
  const location              =   useLocation();
  const context               =   React.useContext(StateContext);
  const [data,setData]        =   React.useState({})
  const [loading,setLoading]  =   React.useState(true)


  getInit=()=>{
    setData({})
    setLoading(true)
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
        setLoading(false)
      }
    })
  }

  React.useEffect(()=>{
    getInit()
  },[location])

  return  <>
            {
              /*
              <Carousel data={gallery}/>
              */
            }

            <Grid container justifyContent="center" spacing={2} sx={{mb:0}} className="bg-white">
              {
                !loading?<>
                  <Grid item xs={11} sx={{ mt: {
                                                  xs:10,
                                                  md:14
                                                },
                                          }} >

                      <Typography component="b" className="text-primary h1">
                        {data.title}
                      </Typography>
                      <Grid className="text-complete">
                        <img src={data.image_fullurl} alt={data.title} className="img-texto"/>
                        { ReactHtmlParser((data.summary==='')?data.summary:data.content) }
                      </Grid>

                  </Grid>
                </>:<>
                  <Loading/>
                </>
              }
            </Grid>
          </>
}
export default App;
