import React,{useState,useEffect,useCallback} from 'react';
import './App.css';
import Modal from './components/common/Modal';
import Loading from './components/common/Loading';
import Snackbars from './components/common/Snackbars';
import {theme} from './helpers/theme';
import { ThemeProvider } from '@mui/material/styles';
import StateContext from './helpers/ContextState';
import {useStore} from './helpers/useStore';
//import {modules} from './helpers/modules';
//import { io } from "socket.io-client";
import localDatabase from './helpers/indexedDB';
import Auth from './components/auth';
import NotFound from './components/common/NotFound';
import WebFreezoneLayout from './components/FreeZone';
import WebProtectedLayout from './components/WebProtectedLayout';


import {  get,
          post,
          put,
          del,
          setFunctions,
          functions
        } from './helpers/api';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

const timeout     =   5000
let   intervalID;
let   init

let   socket
let   position_   =    {
                          latitude:"",
                          longitude:""
                        }
//const freeZone    =     document.location.pathname.includes("open")
const  App=()=>{

  const [user, setUser]                       =   useState(false);
  const [token, setToken]                     =   useState(false);
  const [loading, setLoading]                 =   useState(true);
  const [snackbars, setSnackbars]             =   useState(false);
  const [modalShow, setModalShow]             =   useState(false);
  const [openMessage, setOpenMessage]         =   useState(false);
  const [internet, setInternet]               =   useState(true);
  const [onSubmit, setOnSubmit]               =   useState(false);
  const [position, setPosition]               =   useState(position_);

  const store                                 =   useStore();
  functions.store                             =   store

  /*voy a instanciar la base de datos local*/
  useEffect(()=>{
    localDatabase.createConnection(process.env.REACT_APP_DBNAME)
  },[])

  // useEffect(()=>{
  //   let result    =   modules.find((search)=>search.slug===document.location.pathname);
  //   if (result) {
  //     document.title =  process.env.REACT_APP_TITLE + " - " +result.name
  //   }else {
  //     document.title =  process.env.REACT_APP_TITLE
  //   }
  // },[])

  /*inicialización del socket y sus métodos*/
  useEffect(()=>{
    //console.log(process.env.REACT_APP_SOCKET);
    // socket        =   io( process.env.REACT_APP_SOCKET,{ transports:  ['websocket'], reconnectionDelayMax: 10000,});
    // socket.on('allUsersLocations', function () {
    //   navigator.geolocation.getCurrentPosition((position)=>{
    //     //console.log(position.coords);
    //     socket.emit("geolocation",  {   ...user,
    //                                     latitude:position.coords.latitude,
    //                                     longitude:position.coords.longitude
    //                                 })
    //   }, function(error){});
    // })
  },[user])


  const success = useCallback((position) => {

    position_   =   {
                      latitude:position.coords.latitude,
                      longitude:position.coords.longitude
                    }
    setPosition(position_)

  }, []);

  useEffect(()=>{
    navigator.geolocation.getCurrentPosition(success, function(error){});
  },[success])

  useEffect(()=>{
    if (user && navigator.geolocation) {
      navigator.geolocation.watchPosition(function(position) {
        if ((user && user.token!==undefined)&&position&&position.coords&&position.coords.latitude&&position.coords.longitude) {
          /*
            lo detuve porque manda muchas peticiones al servidor
            en su lugar se lo dejé al socket
          */
          // post('api/users/setGeolocation',{  id:user.token,
          //                                   latitude:position.coords.latitude,
          //                                   longitude:position.coords.longitude
          //                                 },false,false).then((response)=>{
          //
          // })
          // socket.emit("geolocation",  { ...user,
          //                               latitude:position.coords.latitude,
          //                               longitude:position.coords.longitude
          //                             })
        }
      });
    }
  },[user,success])

  // const onClick=()=>{
  //   socket.emit("geolocation",  { ...user,
  //                                 latitude:position.latitude,
  //                                 longitude:position.longitude
  //                               })
  // }

  useEffect(()=>{
    /*
        ESTE EVENTO ESTÁ DIRIGIDO AL BOTÓN GUARDAR EN components/common/Breadcrumbs
        al hacer click ahí, se dispara este evento que está propagado por el ContextState
        y en cada formulario donde lo invoquen, se haga el respectivo api.post()

        Si los formularios no lo invocan, de igual manera a los 4 segundo se vuelve al state false
    */
    if (onSubmit) {
      setTimeout(function(){
        setOnSubmit(false)
      }, 4000);
    }
  },[onSubmit])


  useEffect(()=>{
    if (!user && store.get("user").name) {
      setUser(store.get("user"))
    }else if (user && !store.get("user").name) {
      store.set("user",user)
    }else if (user && user==='close' && store.get("user").name) {
      store.clear()
      setUser(false)
    }

    if (user && user!=='close') {
      /*debo reenviar los datos del usuario si hago F5*/
      // socket.emit("registeruser",{  ...user,
      //                               latitude:position_.latitude,
      //                               longitude:position_.longitude
      //                             })
    }

  },[user,store])

  useEffect(()=>{
    setFunctions({
                    localDatabase:localDatabase,
                    position:position,
                    store:store,
                    setOpenMessage:setOpenMessage,
                    internetStatus:internetStatus,
                    setLoading:setLoading,
                    setModalShow:setModalShow,
                    user:(user)?user:store.get("user"),
                  });

  },[store,user,position])

  useEffect(()=>{
    if (openMessage) {
      setSnackbars({
        show:true,
        message:openMessage,
      })
    }else {
      setSnackbars(false)
    }
  },[openMessage])

  useEffect(()=>{
    if (!internet) {
      intervalID = setInterval(function(){
        if (init) {
          init()
        }
      }, timeout);
    }
  },[internet])

  const internetStatus=(response)=>{
    if (response.message!=='Network Error') {
      clearInterval(intervalID);
      setOpenMessage(false)
      setInternet(true)
    }else if (response.message==='Network Error') {
      setOpenMessage("Estamos presentando inconvenientes de conexión...")
      setInternet(false)
    }
  }

  const provider=()=>{
    return {  user:user,
              onSubmit:onSubmit,
              setOnSubmit:setOnSubmit,
              setUser:setUser,
              setModalShow:setModalShow,
              setSnackbars:setSnackbars,
              token:token,
              setToken:setToken,
              get:get,
              post:post,
              socket:socket,
              position:position,
              put:put,
              delete:del}
  }

  const ProtectedLayout=()=>{
    if (!user  &&  !store.get("user").name) {
      return <Navigate to="/auth/login" />;
    }else {
      return <Navigate to="/admin/main" />;
    }
  }
  return  <ThemeProvider theme={theme}>
            {modalShow?<Modal open={modalShow} setOpen={setModalShow}/>:false}
            <StateContext.Provider value={provider()}>
              <Router>
                <Routes>
                  <Route path="/*" element={<ProtectedLayout />}></Route>
                  <Route path="/web/*" element={<WebFreezoneLayout />}></Route>
                  <Route path="/admin" element={<ProtectedLayout />}></Route>
                  <Route path="/admin/*" element={<WebProtectedLayout />}></Route>
                  <Route path="/auth/*" element={<Auth />}></Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Router>
              {loading?<Loading setLoading={setLoading} loading={loading}/>:false}
              {snackbars?<Snackbars setOpen={setSnackbars} open={snackbars}/>:false}
            </StateContext.Provider>
          </ThemeProvider>

}

export default App;
