import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Input    from '../common/Input';
import BtnSubmit from '../common/BtnSubmit';
import Button from '@mui/material/Button';
import StateContext from '../../helpers/ContextState';
import Select from '../common/Select';
import Loading from '../common/Loading01';


import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {init,set,uri} from '../../helpers/fninputs';
import { useLocation } from "react-router-dom";

import ciudades     from '../../helpers/departamentos_municipios_2022.json';

import InputAdornment from '@mui/material/InputAdornment';
//import AccountCircle from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {increase} from '../../helpers/utilsDate';
import Stack from '@mui/material/Stack';

const btnSubmitId    =   'btnSubmitId'
let getInit

// const inputProps  = {
//                   endAdornment: (
//                     <InputAdornment position="start">
//                       <AccountCircle />
//                     </InputAdornment>
//                   ),
//               }

const App=()=>{
  const location                              =   useLocation();
  const context                               =   React.useContext(StateContext);
  const [loading, setLoading]                 =   useState(true);
  const [inputs, setInputs]                   =   useState({
                                                            // departamento:"Antioquia",
                                                            // municipio:"Medellín",
                                                            // nombre:"Pruebas",
                                                            // lote:"Lote Pruebas",
                                                            // altura:"100",
                                                            // reporte_recomendacion_nutricional:true,
                                                            // reporte_recomendacion_salud_suelo:true,
                                                            // ajustar_programa_fertilizacion:true,
                                                            // analisis_foliar:true,
                                                            // analisis_agua:true,
                                                          });
  const [departaments, setDepartaments]       = useState([]);
  const [cities, setCities]                   = useState([{name:"disabled",id:"0"}]);
  const [crops, setCrops]                     = useState([]);
  const [cropsDetails, setCropsDetails]       = useState([]);
  const [cropsDetail, setCropsDetail]         = useState({});
  const [laboratories, setLaboratories]       = useState([]);
  const [laboratory, setLaboratory]           = useState([]);
  const [dates, setDates]                     = useState([]);
  const [reset, setReset]                     = useState(false);
  const [paises, setPaises]                   = useState([]);

  init(inputs, setInputs)

  useEffect(() => {
    if (reset) {
      setReset(false)
    }
  }, [reset]);

  useEffect(() => {
    let mounted =   true
    orderDepartaments();
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  const orderDepartaments=()=>{
    setDepartaments(ciudades)
    //console.log(inputs.municipio,departaments);
    if (inputs.municipio && inputs.departamento) {
      const result  = ciudades.find((search)=>search.name===inputs.departamento)
      setCities(result.cities)
    }
  }

  getInit=(mounted)=>{
    //return setLoading(false);
    context.get(false,{...context.user},false,false).then((response)=>{

      if (response && response.data && response.data.paises!==null) {
        //console.log(response.data.data);
        setPaises(response.data.paises)
      }

      if (response && response.data && response.data.data!==null) {
        //console.log(response.data.data);
        setInputs(response.data.data)
      }




      // if (response.data && response.data.FieldsByUser && response.data.FieldsByUser.id) {
      //   setInputs(JSON.parse(response.data.FieldsByUser.json))
      //   setReset(true)
      // }
      // if (response.data && response.data.masterTable) {
      //   setCrops(response.data.masterTable)
      // }
      // if (response.data && response.data.content) {
      //   setInputs(response.data.content)
      // }
      // if (response.data && response.data.laboratories) {
      //   let laboratories_=[]
      //   response.data.laboratories.map((row,key)=>{
      //     return laboratories_.push({id:row.name,name:row.name})
      //   })
      //   setLaboratories(laboratories_)
      // }
      // if (response.data && response.data.crops) {
      //   let crops_=[]
      //   response.data.crops.map((row,key)=>{
      //     return crops_.push({id:row.name,name:row.name})
      //   })
      //   setCropsDetails(response.data.crops)
      //   setCrops(crops_)
      // }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmit=(e)=>{
    e.preventDefault()
    context.post(uri(location.pathname).replace("api/","api/v1/"),{  ...inputs,},false,true).then((response)=>{
      if (response && response.data && response.data && response.data.id) {
        setInputs(response.data)
        window.history.go(-1)
      }
    })
  }

  const onChange=(e)=>{

    let inputs__                =   {...inputs}
        inputs__[e.target.name] =   e.target.value
        setInputs(inputs__)
        // if (e.target.name==="departamento") {
        //   const result  = ciudades.find((search)=>search.name===e.target.value)
        //   setCities(result.cities)
        // }
        if (e.target.name==="cultivo") {
          const result  = cropsDetails.find((search)=>search.name===e.target.value)
          setCropsDetail(result)
        }

        if (e.target.name==="laboratorio") {
          getLaboratories(e.target.value)
        }

        if (e.target.name==="F1") {
          context.post("api/calculateDate",{...cropsDetail,init:e.target.value},false,false).then((response)=>{
            if (response && response.data) {
              setDates(response.data)
            }
          })
        }
  }


  const getLaboratories=(value)=>{
    context.get("api/laboratory/"+value,{},false,true).then((response)=>{
      if (response && response.data && response.data.items) {
        setLaboratory(response.data.items)
      }
    })
  }


  useEffect(() => {
    if (inputs.laboratorio) {
      getLaboratories(inputs.laboratorio)
    }
  }, [inputs.laboratorio]);


  useEffect(() => {
    if (inputs.pais) {
      const result  = paises.find((search)=>search.label===inputs.pais)
      if (result && result.departamentos) {
        setDepartaments(result.departamentos)
      }
    }
  }, [inputs.pais]);

  useEffect(() => {
    if (inputs.departamento) {
      context.get("api/v1/admin/setting/municipios_x_dptos/"+inputs.departamento,{},false,true).then((response)=>{
        if (response && response.data) {
          setCities(response.data)
        }
      })
    }
  }, [inputs.departamento]);



  return  <>{loading || reset?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid  item xs={12} md={11} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>
                      <Grid className="blocks">
                        <Grid className="header">Información básica</Grid>
                        <Grid className="body">
                          <Grid container spacing={2}>
                            <Grid  item xs={12} md={2}>
                              <Input
                                      required
                                      fullWidth
                                      type="text"
                                      label="Nombre finca"
                                      defaultValue={inputs.name}
                                      name="name"
                                      onChange={set}
                                      />
                            </Grid>
                            <Grid  item xs={12} md={2}>
                              <Select data={paises}
                                      byId="label"
                                      name="pais"
                                      sx={{m:0}}
                                      defaultValue={inputs.pais}
                                      placeholder='País'
                                      inputs={inputs}
                                      setInputs={onChange}
                                      label="País"/>
                            </Grid>
                            <Grid  item xs={12} md={2}>
                              {inputs.departamento?<>
                                  <Input
                                        disabled
                                        fullWidth
                                        type="text"
                                        label="Nombre finca"
                                        defaultValue={inputs.departamento}
                                        name="departamento"
                                        onChange={set}
                                        />

                                </>:<Select data={departaments}
                                      name="departamento"
                                      sx={{m:0}}
                                      defaultValue={inputs.departamento}
                                      placeholder='Departamento'
                                      inputs={inputs}
                                      setInputs={onChange}
                                      label="Departamento"/>}

                            </Grid>
                            <Grid  item xs={12} md={3}>
                              {inputs.municipio?<>
                                <Input
                                      disabled
                                      fullWidth
                                      type="text"
                                      label="Nombre finca"
                                      defaultValue={inputs.municipio}
                                      name="departamento"
                                      onChange={set}
                                      />

                                </>:<Select data={cities}
                                      name="municipio"
                                      disabled={inputs.municipio?false:true}
                                      sx={{m:0}}
                                      defaultValue={inputs.municipio}
                                      placeholder='Municipio'
                                      inputs={inputs}
                                      setInputs={set}
                                      label="Municipio"/>}

                            </Grid>

                            <Grid  item xs={12} md={3}>
                              <Input
                                      fullWidth
                                      type="text"
                                      label="Ubicación"
                                      defaultValue={inputs.ubicacion}
                                      name="ubicacion"
                                      onChange={set}
                                      />
                            </Grid>

                            <Grid  item xs={12} md={4} >
                              <Stack spacing={2} direction="row">
                                <Button type="submit"
                                        variant="contained">
                                    Guardar
                                </Button>
                                <Button onClick={()=>window.history.go(-1)}
                                        variant="outlined"
                                        >
                                    Cancelar
                                </Button>
                              </Stack>
                            </Grid>


                          </Grid>
                        </Grid>
                      </Grid>

                      {inputs.cultivo?<>

                        <Grid className="blocks">
                          <Grid className="header">
                            <Grid container spacing={2}>
                              <Grid  item xs={12} md={4} align="center">
                                Fases fenológicas
                              </Grid>
                              <Grid  item xs={12} md={4} className="text-center">
                                Fecha inicio
                              </Grid>
                              <Grid  item xs={12} md={4} className="text-center">
                                Días que dura la fase
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid className="body">
                            <Grid container spacing={2}>
                              {cropsDetail.F1!==''&&cropsDetail.F1!==null?<>
                                <Grid  item xs={12} md={4} align="center">
                                  {cropsDetail.F1}
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  <Input
                                        required
                                        fullWidth size="small"
                                        type="date"
                                        label="Fecha inicio"
                                        defaultValue={inputs.F1}
                                        name="F1"
                                        onChange={onChange}
                                        />
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  {cropsDetail.D1}
                                </Grid>
                              </>:false}

                              {cropsDetail.F2!==''&&cropsDetail.F2!==null?<>
                                <Grid  item xs={12} md={4} align="center">
                                  {cropsDetail.F2}
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  <Input
                                        required
                                        fullWidth size="small"
                                        type="date"
                                        label="Fecha inicio"
                                        value={dates.D1}
                                        name="F2"
                                        onChange={onChange}
                                        />
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  {cropsDetail.D2}
                                </Grid>
                              </>:false}
                              {cropsDetail.F3!==''&&cropsDetail.F3!==null?<>
                                <Grid  item xs={12} md={4} align="center">
                                  {cropsDetail.F3}
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  <Input
                                        required
                                        fullWidth size="small"
                                        type="date"
                                        label="Fecha inicio"
                                        value={dates.D2}
                                        name="F3"
                                        onChange={onChange}
                                        />
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  {cropsDetail.D3}
                                </Grid>
                              </>:false}

                              {cropsDetail.F4!==''&&cropsDetail.F4!==null?<>
                                <Grid  item xs={12} md={4} align="center">
                                  {cropsDetail.F4}
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  <Input
                                        required
                                        fullWidth size="small"
                                        type="date"
                                        label="Fecha inicio"
                                        value={dates.D3}
                                        name="F4"
                                        onChange={onChange}
                                        />
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  {cropsDetail.D4}
                                </Grid>
                              </>:false}

                              {cropsDetail.F5!==''&&cropsDetail.F5!==null?<>
                                <Grid  item xs={12} md={4} align="center">
                                  {cropsDetail.F5}
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  <Input
                                        required
                                        fullWidth size="small"
                                        type="date"
                                        label="Fecha inicio"
                                        value={dates.D4}
                                        name="F5"
                                        onChange={onChange}
                                        />
                                </Grid>
                                <Grid  item xs={12} md={4} className="text-center">
                                  {cropsDetail.D5}
                                </Grid>
                              </>:false}

                            </Grid>
                          </Grid>
                        </Grid>



                        <Grid className="blocks">
                          <Grid className="header">Análisis de suelos</Grid>
                          <Grid className="body">
                            <Grid container spacing={2}>
                              <Grid  item xs={12} sx={{mb:0}}>
                                <Select data={laboratories}
                                        name="laboratorio"
                                        sx={{m:0}}
                                        defaultValue={inputs.laboratorio}
                                        placeholder='Laboratorio'
                                        inputs={inputs}
                                        setInputs={onChange}
                                        label="Laboratorio"/>

                              </Grid>

                              <Grid  item xs={12} md={6} sx={{mb:0}}>

                                <Input

                                        required
                                        fullWidth size="small"
                                        type="text"
                                        label="Profundidad de muestreo (Cms)"
                                        defaultValue={inputs.productividad_muestreo}
                                        name="productividad_muestreo"
                                        onChange={set}
                                        />

                              </Grid>

                              <Grid  item xs={12} md={6} sx={{mb:0}}>
                                <Input
                                      required
                                      fullWidth size="small"
                                      type="date"
                                      label="Fecha muestreo"
                                      defaultValue={inputs.fecha_muestreo}
                                      name="fecha_muestreo"
                                      onChange={set}
                                      />

                              </Grid>
                              {inputs.laboratorio?<>
                                <Grid  item xs={12} className="text-center border-green">
                                  Información de muestra
                                </Grid>
                                <Grid  item xs={12} className="text-center2 ">
                                  {laboratory && laboratory.length>0?<>
                                    {laboratory.map((row,key)=>{
                                      return <Grid container key={key} className="border-green" sx={{mb:3}}>
                                                <Grid  item xs={12} className="vertical-align">
                                                  <Grid className="mb-1">
                                                    <Grid className="header">{row.grupo}</Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid  item xs={12}  className="vertical-align">
                                                  {row.items.map((row2,key2)=>{
                                                    return <Grid container key={key2} sx={{mb:1}}>
                                                              <Grid  item xs={12} md={4} className="vertical-align text-">
                                                                <b >{row2.elemento_laboratorio}</b>
                                                                <br/><small>{row2.metodo}</small>
                                                              </Grid>
                                                              <Grid  item xs={12} md={6} className="vertical-align">
                                                                <Input
                                                                      required
                                                                      fullWidth size="small"
                                                                      type="text"
                                                                      label={row2.elemento_laboratorio}
                                                                      defaultValue={inputs[row2.elemento_laboratorio]}
                                                                      name={row2.elemento_laboratorio}
                                                                      onChange={set}
                                                                />
                                                              </Grid>
                                                              <Grid  item xs={12} md={2} className="vertical-align text-right">
                                                                <b>{row2.medidas}</b>
                                                              </Grid>
                                                            </Grid>
                                                  })}
                                                </Grid>
                                              </Grid>
                                    })}

                                  </>:false}
                                </Grid>
                              </>:false}

                              <Grid  item xs={12} className="">
                                <Grid container>
                                  <Grid item xs={12} md={4}>

                                  </Grid>
                                  <Grid item xs={12} md={4} className="text-center">

                                  </Grid>
                                  <Grid item xs={12} md={4} className="text-center">
                                    <Button variant="contained" type="submit">Generar Consulta</Button>
                                  </Grid>
                                </Grid>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Grid>

                      </>:false}
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
