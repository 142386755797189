import React from 'react';
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import StateContext from '../../helpers/ContextState';

let getInit

const App=()=>{

  const context               =   React.useContext(StateContext);
  const [data,setData]        =   React.useState({})
  const [loading, setLoading] =   React.useState(false);

  getInit=(mounted)=>{
    setLoading(true)
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
        //console.log(response.data);
      }
      setLoading(false)
    })
  }

  React.useEffect(()=>{
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  },[]);




  return <Grid container justifyContent="center">
            {
              !loading && (data && data.video_fullurl)?<>
                <Grid item xs={5} sx={{pt:12}}>
                  <video controls autoPlay style={{maxWidth:"100%"}}>
                    <source src={data.video_fullurl} type="video/mp4"/>
                  </video>
                </Grid>
                <Grid item xs={7} sx={{pt:12,pl:3,pr:3}}>
                  <Typography component="h1" className="title-course-main">
                    <b>{data.name}</b>
                  </Typography>
                  <Grid sx={{mb:2}}>
                    <Typography component="p" className="title-course-p">
                      {data.summary}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2}>
                      <Grid className="circle"></Grid>
                    </Grid>
                    <Grid item  xs={12} md={10}>
                      <Grid className="vertical-center-container">
                        <Grid className="vertical-center t-12">
                          <Grid>
                            <b>Lorem ipsum dolor sit amet</b>
                          </Grid>
                          <Grid className="">
                            consectetuer adipiscing elit
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{pt:6}}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={5}>
                      <Typography component="h1" className="title-course-main">
                        <b>¿Qué aprenderás en este curso?</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      111
                    </Grid>
                  </Grid>
                </Grid>
              </>:false
            }

          </Grid>
}

export default App
