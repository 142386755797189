import React from 'react';
import Grid from '@mui/material/Grid';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Button   from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import Table from '../common/Table';

const title =   "Elemento"

const td  = [
              {
                label:"Medida",
                value:"label",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["edit"]
              },
            ]

const App=({usePrivileges,endpoint})=>{
  return    <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12}  sx={{
                                            mt:{ xs: 1, md: 0 },
                                            mb: { xs: 1, md: 0 },
                                            p:{ xs: 1, md: 0 },
                                          }}>
                    <Table component={<Button variant="outlined" startIcon={<ControlPointIcon/>} size="small" fullWidth sx={{ mb: { xs: 1, md: 0 },  }} component={NavLink} to={"0"}>Agregar {title}</Button>} td={td} endpoint={endpoint?endpoint:false}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
}
export default App
