import * as React from 'react';
import Typography from '@mui/material/Typography';
import {modules_protect,menu_user} from '../../helpers/modules';
import { Link } from "react-router-dom";
import ItemSubMenu from './ItemSubMenu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


const prefijo           =   process.env.REACT_APP_PREFIJOADMIN
const open=true

const ResponsiveAppBar  =   (props) => {
  // const seo=(row)=>{
  //   document.title =  process.env.REACT_APP_NAME+" - "+row.name
  // }

  const handleCloseNavMenu=()=>{

  }

  return      <List
                component="nav"
                aria-label="Device settings"

              >
                {
                  modules_protect.map((page,key) =>
                    (page.items && page.items.length===0) && page.menu?<ListItem
                                                                                  button
                                                                                  id="lock-button"
                                                                                  aria-haspopup="listbox"
                                                                                  aria-controls="lock-menu"
                                                                                  aria-label="when device is locked"
                                                                                  aria-expanded={open ? 'true' : undefined}
                                                                                  onClick={handleCloseNavMenu}
                                                                                key={key}
                                        component={Link}
                                        to={prefijo+page.slug}
                                        >
                                <Typography textAlign="left">
                                  { page.name}
                                </Typography>
                              </ListItem>:(page.items && page.items.length>0) && page.menu?<ItemSubMenu prefijo={prefijo} page={page} handleCloseNavMenu={handleCloseNavMenu} label={page.name} data={page.items} key={key}/>:false)
                }

                {
                  menu_user.map((page,key) =>
                    (page.items && page.items.length===0) && page.menu?<ListItem
                                                                                  button
                                                                                  id="lock-button"
                                                                                  aria-haspopup="listbox"
                                                                                  aria-controls="lock-menu"
                                                                                  aria-label="when device is locked"
                                                                                  aria-expanded={open ? 'true' : undefined}
                                                                                  onClick={handleCloseNavMenu}
                                                                                key={key}
                                        component={Link}
                                        to={prefijo+page.slug}
                                        >
                                <Typography textAlign="left">
                                  { page.name}
                                </Typography>
                              </ListItem>:(page.items && page.items.length>0) && page.menu?<ItemSubMenu prefijo={prefijo} page={page} handleCloseNavMenu={handleCloseNavMenu} label={page.name} data={page.items} key={key}/>:false)
                }
              </List>
};
export default ResponsiveAppBar;
