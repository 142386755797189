import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Grid } from '@mui/material'
//import { NavLink } from "react-router-dom";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
//const AutoPlaySwipeableViews = SwipeableViews;

function SwipeableTextMobileStepper(props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.data.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return  <>
            {
              props.data && props.data.length>0?<>
                <Grid container spacing={2} sx={{ pt: { xs:0 , md: 0 },
                                                  pb: { xs:0 , md: 0 },
                                                  mt: { xs:0 , md: 0 },
                                                }}>
                  <Grid  item xs={12} className="slider_content">
                    <AutoPlaySwipeableViews
                        interval={5000}
                        autoPlay={false}
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents>
                          {props.data.map((step, index) => (
                            <div key={index}>
                              <Grid className="slider_item">
                                <Grid className="position-relative">
                                  <Grid className="content">
                                    { (step.textUnique1&& step.textUnique1!==''&& step.textUnique1!=='null') ||
                                      (step.textUnique2&& step.textUnique2!=='' && step.textUnique2!=='null')?<Grid className="slider_item_label_center">
                                      <Grid className="h1 text-white">
                                        {step.textUnique1}
                                      </Grid>
                                      <Grid className="h2 text-white2">
                                        {step.textUnique2}
                                      </Grid>
                                    </Grid>:false}
                                    {step.label && step.label!=='' && step.label!=='null'?<Grid className="slider_item_label">
                                      <Grid className="position-relative-">
                                        <span className="">{step.label}</span>
                                        {step.text && step.text!=='' && step.text!=='null'?<Grid className="slider_item_text">
                                          {step.text}
                                        </Grid>:false}
                                      </Grid>
                                    </Grid>:false}
                                  </Grid>
                                </Grid>
                                <Box
                                      component="img"
                                      sx={{
                                      display: 'block',
                                      overflow: 'hidden',
                                      width: '100%',
                                      }}
                                      src={step.image_fullurl}
                                      alt={step.text}/>
                              </Grid>
                            </div>
                          ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                      steps={maxSteps}
                      position="static"
                      activeStep={activeStep}
                      nextButton={
                        <Button
                          size="small"
                          onClick={handleNext}
                          disabled={activeStep === maxSteps - 1}
                        >
                          Siguiente
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      }
                      backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                          Anterior
                        </Button>
                      }
                    />
                  </Grid>
                </Grid>
              </>:<>

              </>
            }
          </>

}

export default SwipeableTextMobileStepper;
