import React from 'react';
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
//import StateContext from '../../helpers/ContextState';

//let getInit

//const elementsLoading   =   [1,2,3]

const App=({data,xs,md})=>{

  //const context               =   React.useContext(StateContext);
  //const [select,setSelect]    =   React.useState(0)
  //const [loading, setLoading] =   React.useState(true);

  // getInit=()=>{
  //   context.get(false,{...context.user},false,false).then((response)=>{
  //     if (response.data) {
  //       console.log(response.data);
  //     }
  //     setLoading(false)
  //   })
  // }

  return  <Grid container justifyContent="center">
            <Grid item xs={12} className="bg-white" sx={{p:2}}>
              <Typography component="h1" className="text-center title-main">
                <b>Testimonios</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} className="bg-white" sx={{p:4}}>
              <Grid container justifyContent="center" spacing={5}>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="194"
                      image="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png"
                      alt="Programandoweb"
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="194"
                      image="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png"
                      alt="Programandoweb"
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="194"
                      image="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png"
                      alt="Programandoweb"
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
