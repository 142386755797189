import React from 'react';
import Grid from '@mui/material/Grid';
import Loading from '../common/Loading01';
import Carousel from '../common/Carousel';
import BlockNewsStatic from '../freezone/BlockOne';
import Benefits from '../freezone/Benefits';
import Cta from '../freezone/Cta';
import Packages from '../freezone/Packages';
import Affiliates from '../freezone/Affiliates';
import AboutUs from '../freezone/AboutUs';
import Testimonials from '../freezone/Testimonials';
import Contact from '../freezone/Contact';
import StateContext from '../../helpers/ContextState';
//import {slider,news,questions} from '../../helpers/faker';

let getInit

const App=()=>{
  const [loading,setLoading]  =   React.useState(false)
  const [data,setData]        =   React.useState({})
  const context               =   React.useContext(StateContext);

  getInit=(mounted)=>{
    setLoading(true)
    context.get('api/freeZone/home',{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
      }
      setLoading(false)
    })
  }

  React.useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  return  <>
              <Grid sx={{ p: 2}}/>
              {loading?<>
                  <Loading height={'60vh'}/>
                </>:<>
                  {data.slider?<Grid sx={{ flexGrow: 1 }} container spacing={0}>
                    <Grid item xs={12}>
                      <Carousel data={data.slider}/>
                    </Grid>
                  </Grid>:false}

                  {data.categories?<Grid>
                    <BlockNewsStatic data={data.categories}/>
                  </Grid>:false}

                  {data.categories?<Grid>
                    <Benefits data={data.categories}/>
                  </Grid>:false}

                  {data.categories?<Grid className="block block-gray">
                    <Cta data={data.categories}/>
                  </Grid>:false}

                  {data.categories?<Grid>
                    <Packages data={data.categories}/>
                  </Grid>:false}

                  {data.categories?<Grid>
                    <Affiliates data={data.categories}/>
                  </Grid>:false}

                  {data.categories?<Grid>
                    <AboutUs data={data.categories}/>
                  </Grid>:false}

                  {data.categories?<Grid>
                    <Testimonials data={data.categories}/>
                  </Grid>:false}

                  {data.categories?<Grid>
                    <Contact data={data.categories}/>
                  </Grid>:false}

                </>
              }
          </>
}

export default App
