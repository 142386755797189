import React from 'react';
import { useEffect } from 'react';
import StateContext from '../../helpers/ContextState';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NavLink } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
//import { io } from "socket.io-client";
import {useStore} from '../../helpers/useStore';

import { useNavigate } from "react-router-dom";

import {
          inputs,
          useInputs
        } from '../../helpers/useInputs';

//const socket  =   io( process.env.REACT_APP_SOCKET,{ transports:  ['websocket'], reconnectionDelayMax: 10000,});
let store     =   false
let context   =   false
const Login=()=>{
  context         =   React.useContext(StateContext);
  store           =   useStore();
  const { uid }   =   useParams();
  const navigate  =   useNavigate();

  useEffect(()=>{
    if (uid) {
      context.post(false,{token:uid},false,true).then((response)=>{
        if (response.data && response.data.user) {

          // socket.emit("registeruser",{  ...response.data.user,
          //                               latitude:context.position.latitude,
          //                               longitude:context.position.longitude
          //                             })

          store.set("user",{...response.data.user,access_token:response.data.access_token})

          document.title =  process.env.REACT_APP_NAME + " - hola " +response.data.user.name
          context.setUser({...response.data.user,access_token:response.data.access_token})
          context.setToken(response.data.access_token)
          return navigate("/admin");
        }
      })
    }
  },[uid,navigate])

  useEffect(()=>{
    document.title =  process.env.REACT_APP_NAME + " - inicio de sesión"
  },[])

  const handleSubmit=(e)=>{
    e.preventDefault()
    context.post(false,inputs,false,false,true).then((response)=>{
      if (response.data && response.data.user) {
        // context.socket.emit("registeruser",{  ...response.data.user,
        //                                       latitude:context.position.latitude,
        //                                       longitude:context.position.longitude
        //                                     })
        document.title =  process.env.REACT_APP_NAME + " - hola " +response.data.user.name
        context.setUser({...response.data.user,access_token:response.data.access_token})
        context.setToken(response.data.access_token)
        return navigate("/admin");
      }
    })
  }

  return <>
          <Typography component="h1" variant="h5">
            Iniciar sesión
          </Typography>
          <form  onSubmit={handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="email"
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={useInputs}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={useInputs}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}> Iniciar sesión </Button>

              <Grid container>
                <Grid item xs>
                  <NavLink to="/auth/recover" variant="body2">
                    <Typography>
                      Olvidé contraseña
                    </Typography>
                  </NavLink>
                </Grid>
                <Grid item xs>
                  <NavLink to="/auth/register" variant="body2">
                    <Typography>
                      Registrarme
                    </Typography>
                  </NavLink>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
}

export default Login
