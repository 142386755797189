import React from 'react';
import Grid from '@mui/material/Grid';
import Table from '../common/Table';

//const title =   "Equivalencia"

const td  = [
              {
                label:"Nombre Variable en Reporte final",
                value:"label",
                align:"left"
              },
              {
                label:"UM",
                value:"medida",
                align:"left"
              },
              {
                label:"PPM",
                value:"value",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["edit"]
              },
            ]

const App=({usePrivileges,endpoint})=>{
  return    <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12}  sx={{
                                            mt:{ xs: 1, md: 0 },
                                            mb: { xs: 1, md: 0 },
                                            p:{ xs: 1, md: 0 },
                                          }}>
                    <Table td={td} endpoint={endpoint?endpoint:false}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
}
export default App
