import React from 'react';
import { Grid } from '@mui/material'
import {
  Routes,
  Route,
} from "react-router-dom";
import {modules} from '../helpers/modules';
import { usePrivileges } from '../helpers/usePrivileges';
import {get,set} from '../helpers/useStore';
import MenuFreezone from './freezone/Menu';
import Footer from './common/Footer';
import { ThemeProvider } from '@mui/material/styles';
import StateContext from '../helpers/ContextState';
import {theme} from './freezone/theme';

let getInit

const App=({  onSubmit , setOnSubmit})=>{
  const context               =   React.useContext(StateContext);
  const [data,setData]        =   React.useState({})

  getInit=(mounted)=>{
    context.get('api/freeZone/basic_config',{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
        if (!get("footer").slider) {
          set("footer",response.data)
        }
      }
    })
  }

  React.useEffect(() => {
    let mounted =   true
    if (!get("footer").slider) {
      getInit(mounted)
    }else {
      setData(get("footer"))
    }
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  return    <ThemeProvider theme={theme}>
              <MenuFreezone/>
              <Routes>
                {modules.map((row,key)=>{
                  if (row.items.length===0) {
                    return  <Route key={key} path={row.slug} element={<>{<row.component usePrivileges={usePrivileges} />}</>}/>
                  }else {
                    return row.items.map((row2,key2)=>{
                      if (key2===0) {
                        return  <>
                                  <Route key={key}  path={row.slug} element={<>{<row.component usePrivileges={usePrivileges} />}</>}/>
                                  <Route key={key2} path={row2.slug} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                                </>
                      }else {
                        return <Route key={key2} path={row2.slug} element={<>{<row2.component usePrivileges={usePrivileges} />}</>}/>
                      }
                    })
                  }

                })}
              </Routes>
              <Grid className="block-footer">
                <Footer data={data.footer}/>
              </Grid>
            </ThemeProvider>

}

export default App
