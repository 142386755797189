import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';


import logo from '../assets/images/logo-menu.png';
import CardMedia from '@mui/material/CardMedia';
import {
  Routes,
  Route,
} from "react-router-dom";
import {modules_protect,menu_user} from '../helpers/modules';
import NotFound from '../components/common/NotFound';
import MenuDrawer from '../components/common/MenuDrawer';
import StateContext from '../helpers/ContextState';

import {useStore} from '../helpers/useStore';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}


export default function ResponsiveDrawer(props: Props) {

  const context                               =   React.useContext(StateContext);
  const store                                 =   useStore();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(open?false:true);
  };

  const drawer = <MenuDrawer/>

  React.useEffect(()=>{

    if (!store.get("user").token) {
      document.location.href  =   './'
      //console.log(500);
      //console.log(context.user,store.get("user"));
      //
    }
  },[])

  if (context.user) {
    return (
      <Box sx={{ display: 'flex' }}>
        <AppBar
          open={open}
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${open?drawerWidth:0}px)` },
            ml: { sm: `${open?drawerWidth:0}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2}}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              <CardMedia
                component="img"
                height="40"
                image={logo}
                alt="Paella dish"
              />
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: open?drawerWidth:0 }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <Drawer
            variant="persistent"
            open={open}
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: open?drawerWidth:0 },
            }}
          >
            <Typography align="right" className="t-36">
              <CloseIcon sx={{mr:2}} onClick={()=>setOpen(false)} className="cursor-pointer"/>
            </Typography>
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Routes>
            {menu_user.map((row,key)=>{
              if (row.items&&row.items.length===0) {
                return  <Route key={key} path={row.slug} element={<>{<row.component />}</>}/>
              }else {
                return row.items.map((row2,key2)=>{
                  if (key2===0) {
                    return  <>
                              <Route key={key}  path={row.slug} element={<>{<row.component />}</>}/>
                              <Route key={key2} path={row2.slug} element={<>{<row2.component />}</>}/>
                            </>
                  }else {
                    return <Route key={key2} path={row2.slug} element={<>{<row2.component />}</>}/>
                  }
                })
              }
            })}
            {modules_protect.map((row,key)=>{
              if (row.items&&row.items.length===0) {
                return  <Route key={key} path={row.slug} element={<>{<row.component />}</>}/>
              }else {
                return row.items.map((row2,key2)=>{
                  if (key2===0) {
                    return  <>
                              <Route key={key}  path={row.slug} element={<>{<row.component />}</>}/>
                              <Route key={key2} path={row2.slug} element={<>{<row2.component />}</>}/>
                            </>
                  }else {
                    return <Route key={key2} path={row2.slug} element={<>{<row2.component />}</>}/>
                  }
                })
              }
            })}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </Box>
    );
  }else {
    return false
  }

}
