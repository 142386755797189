import { createTheme } from '@mui/material/styles';
import { blue,grey } from '@mui/material/colors';
export const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem',
          //color:"#333",
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Raleway',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: "#fff",
    },
    danger: {
      main: grey[900],
      contrastText: '#ffffff',
    },
  },

});
