import React from 'react';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material'
import { NavLink } from "react-router-dom";
import img1 from '../../assets/images/website-programandoweb.png';
import img4 from '../../assets/images/frontend.jpg'
import img5 from '../../assets/images/backend.jpg'
import img6 from '../../assets/images/website-programandoweb-2.png'
import img7 from '../../assets/images/website-programandoweb-1.png'
import FormContact from '../common/FormContact';
import transparent from '../../assets/images/transparent.png'

const App=()=>{

  React.useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }, [])

  return  <>
            <Grid container justifyContent="center"  spacing={2} sx={{mb:0}} className="bg-white website">
              <Grid item xs={12} md={6}  sx={{ mt: {
                                              xs:10,
                                              md:14
                                            },
                                      }}

                >
                <Typography component="h2" className="title-banner text-center">
                  Desarrollo de <b>Páginas Web</b>
                </Typography>
                <Typography component="p" className="text-center p-2">
                  Realizamos un desarrollo preciso para su proceso de extremo a extremo,
                  ofrecemos un equipo de <b>gran experiencia con alta capacidad de desarrollo con tecnología de vanguardia</b>.
                </Typography>
                <Grid className="text-center">
                  <Button  component={NavLink} variant="contained" className={"mt-2"} to="/contact/buenos-dias,-quiero-cotizar-mi-propia-página-web">
                    Quiero mi Página Web
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}  sx={{ mt: {
                                              xs:10,
                                              md:14
                                            },
                                      }} >
                <img src={img1} alt="" className="img-fluid"/>
              </Grid>
              <Grid item xs={12}>
                <Grid className="separator">
                  <img src={transparent} alt={"programandoweb"} className="img-fluid"/>
                </Grid>
              </Grid>
              <Grid item xs={12} className="content-primary">
                <Typography component="h4" className="text-center text-white h4 mb-2 uppercase">
                  Cubrimos las expectativas de tus usuarios con un páginas Web estáticas o dinámicas.
                </Typography>
                <Grid></Grid>
                <Grid container justifyContent="center"  spacing={2} sx={{mb:0}}>
                  <Grid item xs={12} md={3} className="text-center p-2">
                    <Typography component="h1" className="text-center text-white mb-2 uppercase roboto">
                      Desarrollo web <b>CMS Privado</b>
                    </Typography>
                    <CardMedia
                      component="img"
                      image={img4}
                      alt="programandoweb"
                      title="Aplicacione en React JS"
                    />
                    <Typography component="h4" className="text-center text-white h5 mb-2">
                      Instalamos tu propio <b>CMS</b> a la medida con tu dominio, con velocidades de respuesta increibles.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={1}>
                  </Grid>

                  <Grid item xs={12} md={3} className="text-center p-2">
                    <Typography component="h1" className="text-center text-white mb-2 uppercase roboto">
                      Desarrollo web <b>Wordpress</b>
                    </Typography>
                    <CardMedia
                      component="img"
                      image={img5}
                      alt="programandoweb"
                      title="Aplicacione en React JS"
                    />
                    <Typography component="h4" className="text-center text-white h5 mb-2">
                      Lleva la Mejor Velocidad y Seguridad para tu Sitio web en <b>WordPress</b> con la mejor alternativa calidad / precio.
                    </Typography>
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid className="separator2">
                  <img src={transparent} alt={"programandoweb"} className="img-fluid"/>
                </Grid>
              </Grid>




              <Grid item xs={12}>
                <Typography component="h4" className="text-center h4 mb-2 uppercase">
                  Una mejor <b>UI</b> mejora tu producto
                </Typography>
              </Grid>



              <Grid container justifyContent="center"  spacing={0} sx={{mb:0}}>


                <Grid item xs={12} md={3} className="text-center p-2">
                  <CardMedia
                    component="img"
                    image={img6}
                    alt="programandoweb"
                    title="PWA"
                  />
                  <Typography component="h4" className="text-center  h5 mb-2">
                    Interfaz de usuario amigable
                  </Typography>
                </Grid>

                <Grid item xs={12} md={1} className="text-center p-2">
                </Grid>


                <Grid item xs={12} md={3} className="text-center p-2">
                  <CardMedia
                    component="img"
                    image={img7}
                    alt="programandoweb"
                    title="Aplicacione Web"
                  />
                  <Typography component="h4" className="text-center  h5 mb-2">
                    Flexibilidad y eficiencia de uso
                  </Typography>
                </Grid>

                <Grid item xs={12} className="text-center p-2">
                  <FormContact/>
                </Grid>
              </Grid>

            </Grid>
          </>
}
export default App;
