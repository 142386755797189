import * as React from 'react';
import {useState,useEffect} from 'react';
import TextField from '@mui/material/TextField';

const App=({  onChange,
              label,
              name,
              margin,
              type,
              autoFocus,
              fullWidth,
              required,
              autoComplete,
              color,
              size,
              multiline,
              rows,
              defaultValue,
              className,
              min,
              max,
              maxLength,
              onKeyPress,
              disabled,
              InputProps,
              value})=>{
  const [reset, setReset]       = useState(false)

  useEffect(()=>{
    //setReset(true)
  },[defaultValue])

  useEffect(()=>{
    if (reset) {
      setReset(false)
    }
  },[reset])

  return  <>{!reset?<TextField
                      InputLabelProps={{ shrink: true }}
                      margin={margin}
                      required={required}
                      disabled={disabled}
                      fullWidth={fullWidth}
                      multiline={multiline}
                      defaultValue={defaultValue}
                      value={value}
                      className={className}
                      maxLength={maxLength}
                      min={min}
                      max={max}
                      size={size}
                      rows={rows}
                      type={type}
                      color={(color)?color:"secondary"}
                      id={name}
                      label={label}
                      name={name}
                      autoComplete={autoComplete}
                      autoFocus={autoFocus}
                      onChange={onChange}
                      onKeyPress={onKeyPress}
                      InputProps={InputProps}
                    />:false}
          </>
}
export default App
