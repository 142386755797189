import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Input    from '../common/Input';
import Select    from '../common/Select';

import BtnSubmit from '../common/BtnSubmit';
import Button from '@mui/material/Button';
import StateContext from '../../helpers/ContextState';
import Loading from '../common/Loading01';
import {init,set} from '../../helpers/fninputs';
import Stack from '@mui/material/Stack';

const btnSubmitId    =   'btnSubmitId'
let getInit

const App=()=>{
  const context                               = React.useContext(StateContext);
  const [loading, setLoading]                 = useState(true);
  const [inputs, setInputs]                   = useState({});
  const [medidas, setMedidas]                 = useState([]);
  //const [grupos, setGrupos]                   = useState([]);

  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  getInit=(mounted)=>{
    setLoading(true);
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data&&response.data.medidas) {
        setMedidas(response.data.medidas)
      }
      // if (response.data&&response.data.grupos) {
      //   setGrupos(response.data.grupos)
      // }

      if (response.data) {
        //setTimeout(function(){ setInputs(response.data) }, 2000);

        setInputs(response.data)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmit=(e)=>{
    e.preventDefault()
    context.post(false,{  ...inputs,},false,true).then((response)=>{
      if (response && response.data && response.data && response.data.id) {
        setInputs(response.data)
        window.history.go(-1)
      }
    })
  }


  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid  item xs={12} md={10} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>
                      <Grid className="blocks">
                        <Grid className="header">Información básica</Grid>
                        <Grid className="body">
                          <Grid container spacing={2}>
                            <Grid  item xs={12} md={4}>
                              <Input
                                      disabled
                                      fullWidth
                                      size="small"
                                      type="text"
                                      label="Nombre"
                                      defaultValue={inputs.label}
                                      name="label"
                                      onChange={set}
                                      />
                            </Grid>
                            <Grid  item xs={12} md={4}>
                              <Input
                                      required
                                      fullWidth
                                      size="small"
                                      type="text"
                                      label="Equivalencia"
                                      defaultValue={inputs.value}
                                      name="value"
                                      onChange={set}
                                      />
                            </Grid>
                            <Grid  item xs={12} md={4}>
                              <Select data={medidas}
                                      name="medida_id"
                                      required={true}
                                      byId={"id"}
                                      defaultValue={inputs.medida_id}
                                      placeholder='Medidas'
                                      inputs={inputs}
                                      setInputs={set}
                                      label="Medidas"
                                      value={inputs.medida_id} />
                            </Grid>

                            <Grid  item xs={12} md={4} >
                              <Stack spacing={2} direction="row">
                                <Button type="submit"
                                        variant="contained">
                                    Guardar
                                </Button>
                                <Button onClick={()=>window.history.go(-1)}
                                        variant="outlined"
                                        >
                                    Cancelar
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
