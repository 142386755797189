import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export default function Variants() {
  return (<Grid container spacing={0}>
            <Grid item xs={12} sx={{ mt: 1, }}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid  item xs={11} sm={11} md={11} >
                  <Paper sx={{ p: 2, }}>
                    <Stack spacing={1}>
                      <Skeleton variant="text" />
                      <Skeleton variant="rectangular" width={"100%"} height={120} />
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
  );
}
