import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Input    from '../common/Input';
import BtnSubmit from '../common/BtnSubmit';
import Button from '@mui/material/Button';
import StateContext from '../../helpers/ContextState';
import Select from '../common/Select';
import Loading from '../common/Loading01';

import {init,set} from '../../helpers/fninputs';
import ciudades     from '../../helpers/departamentos_municipios_2022.json';
import Stack from '@mui/material/Stack';


const btnSubmitId    =   'btnSubmitId'
let getInit

const App=()=>{
  const context                               = React.useContext(StateContext);
  const [loading, setLoading]                 = useState(true);
  const [inputs, setInputs]                   = useState({});
  const [departaments, setDepartaments]       = useState([]);
  const [cities, setCities]                   = useState([{name:"disabled",id:"0"}]);

  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    orderDepartaments();
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  const orderDepartaments=()=>{
    setDepartaments(ciudades)
  }

  getInit=(mounted)=>{
    //return setLoading(false);
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setInputs(response.data)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (inputs.departamento && (cities.length===1 && cities[0].name==="disabled")) {
      const search  =   departaments.find((search)=>search.name===inputs.departamento)
      setCities(search.cities)
    }
  }, [inputs.departamento,cities,departaments]);

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmit=(e)=>{
    e.preventDefault()
    context.post(false,{  ...inputs,},false,true).then((response)=>{
      if (response && response.data && response.data && response.data.id) {
        setInputs(response.data)
        window.history.go(-1)
      }
    })
  }

  const onChange=(e)=>{
    let inputs__                =   {...inputs}
        inputs__[e.target.name] =   e.target.value
        setInputs(inputs__)
        if (e.target.name==="departamento") {
          const result  = ciudades.find((search)=>search.name===e.target.value)
          setCities(result.cities)
        }
  }



  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid  item xs={12} md={10} >
                    <form onSubmit={onSubmit}>
                      <BtnSubmit id={btnSubmitId}/>
                      <Grid className="blocks">
                        <Grid className="header">Información básica</Grid>
                        <Grid className="body">
                          <Grid container spacing={2}>
                            <Grid  item xs={12} >
                              <Input

                                      fullWidth
                                      size="small"
                                      type="text"
                                      label="Cultivo"
                                      defaultValue={inputs.name}
                                      name="name"
                                      onChange={set}
                                      />
                            </Grid>
                            <Grid  item xs={12} md={3}>
                              <Select data={[{name:"Colombia",value:"Colombia"}]}
                                      name="pais"
                                      sx={{m:0}}
                                      defaultValue={"Colombia"}
                                      placeholder='País'
                                      inputs={inputs}
                                      setInputs={onChange}
                                      label="País"/>
                            </Grid>
                            <Grid  item xs={12} md={3}>
                              <Select data={departaments}
                                      name="departamento"
                                      sx={{m:0}}
                                      defaultValue={inputs.departamento}
                                      placeholder='Departamento'
                                      inputs={inputs}
                                      setInputs={onChange}
                                      label="Departamento"/>
                            </Grid>
                            <Grid  item xs={12} md={3}>
                              <Select data={cities}
                                      name="municipio"
                                      disabled={inputs.departamento?false:true}
                                      sx={{m:0}}
                                      defaultValue={inputs.municipio}
                                      placeholder='Municipio'
                                      inputs={inputs}
                                      setInputs={set}
                                      label="Municipio"/>
                            </Grid>
                            <Grid  item xs={12} md={3}>
                              <Input

                                      fullWidth
                                      type="text"
                                      label="Zona"
                                      defaultValue={inputs.zone}
                                      name="zone"
                                      onChange={set}
                                      />
                            </Grid>

                            <Grid  item xs={12}>
                              <Grid className="header">Descripción fase fenológica</Grid>
                            </Grid>

                            <Grid  item xs={12} md={3}>
                              Requerimiento total por toneladas de cosecha
                            </Grid>
                            <Grid  item xs={12} md={9}>
                              <Grid container spacing={1} justifyContent="end" >
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="N"
                                          defaultValue={inputs.N}
                                          name="N"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="P"
                                          defaultValue={inputs.P}
                                          name="P"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="K"
                                          defaultValue={inputs.K}
                                          name="K"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="Ca"
                                          defaultValue={inputs.Ca}
                                          name="Ca"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="Mg"
                                          defaultValue={inputs.Mg}
                                          name="Mg"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="S"
                                          defaultValue={inputs.S}
                                          name="S"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="Fe"
                                          defaultValue={inputs.Fe1}
                                          name="Fe1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="Zn"
                                          defaultValue={inputs.Zn1}
                                          name="Zn1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="Mn"
                                          defaultValue={inputs.Mn1}
                                          name="Mn1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="Cu"
                                          defaultValue={inputs.Cu1}
                                          name="Cu1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="B"
                                          defaultValue={inputs.B1}
                                          name="B1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="Mo"
                                          defaultValue={inputs.X1}
                                          name="X1"
                                          onChange={set}/>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid  item xs={12} md={2}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Fase 1"
                                      defaultValue={inputs.F1}
                                      name="F1"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={1}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Días 1"
                                      defaultValue={inputs.D1}
                                      name="D1"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={9}>
                              <Grid container spacing={1} justifyContent="end" >
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% N"
                                          defaultValue={inputs.N1}
                                          name="N1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% P"
                                          defaultValue={inputs.P1}
                                          name="P1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% K"
                                          defaultValue={inputs.K1}
                                          name="K1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Ca"
                                          defaultValue={inputs.Ca1}
                                          name="Ca1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mg"
                                          defaultValue={inputs.Mg1}
                                          name="Mg1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% S"
                                          defaultValue={inputs.S1}
                                          name="S1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Fe"
                                          defaultValue={inputs.Fe1}
                                          name="Fe1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Zn"
                                          defaultValue={inputs.Zn1}
                                          name="Zn1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mn"
                                          defaultValue={inputs.Mn1}
                                          name="Mn1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Cu"
                                          defaultValue={inputs.Cu1}
                                          name="Cu1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% B"
                                          defaultValue={inputs.B1}
                                          name="B1"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mo"
                                          defaultValue={inputs.X1}
                                          name="X1"
                                          onChange={set}/>
                                </Grid>

                              </Grid>
                            </Grid>

















                            <Grid  item xs={12} md={2}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Fase 2"
                                      defaultValue={inputs.F2}
                                      name="F2"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={1}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Días 2"
                                      defaultValue={inputs.D2}
                                      name="D2"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={9}>
                              <Grid container spacing={1} justifyContent="end" >
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% N"
                                          defaultValue={inputs.N2}
                                          name="N2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% P"
                                          defaultValue={inputs.P2}
                                          name="P2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% K"
                                          defaultValue={inputs.K2}
                                          name="K2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Ca"
                                          defaultValue={inputs.Ca2}
                                          name="Ca2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mg"
                                          defaultValue={inputs.Mg2}
                                          name="Mg2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% S"
                                          defaultValue={inputs.S2}
                                          name="S2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Fe"
                                          defaultValue={inputs.Fe2}
                                          name="Fe2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Zn"
                                          defaultValue={inputs.Zn2}
                                          name="Zn2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mn"
                                          defaultValue={inputs.Mn2}
                                          name="Mn2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Cu"
                                          defaultValue={inputs.Cu2}
                                          name="Cu2"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% B"
                                          defaultValue={inputs.B2}
                                          name="B2"
                                          onChange={set}/>
                                </Grid>

                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mo"
                                          defaultValue={inputs.X2}
                                          name="X2"
                                          onChange={set}/>
                                </Grid>

                              </Grid>
                            </Grid>













                            <Grid  item xs={12} md={2}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Fase 3"
                                      defaultValue={inputs.F3}
                                      name="F3"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={1}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Días 3"
                                      defaultValue={inputs.D3}
                                      name="D3"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={9}>
                              <Grid container spacing={1} justifyContent="end" >
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% N"
                                          defaultValue={inputs.N3}
                                          name="N3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% P"
                                          defaultValue={inputs.P3}
                                          name="P3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% K"
                                          defaultValue={inputs.K3}
                                          name="K3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Ca"
                                          defaultValue={inputs.Ca3}
                                          name="Ca3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mg"
                                          defaultValue={inputs.Mg3}
                                          name="Mg3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% S"
                                          defaultValue={inputs.S3}
                                          name="S3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Fe"
                                          defaultValue={inputs.Fe3}
                                          name="Fe3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Zn"
                                          defaultValue={inputs.Zn3}
                                          name="Zn3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mn"
                                          defaultValue={inputs.Mn3}
                                          name="Mn3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Cu"
                                          defaultValue={inputs.Cu3}
                                          name="Cu3"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% B"
                                          defaultValue={inputs.B3}
                                          name="B3"
                                          onChange={set}/>
                                </Grid>

                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mo"
                                          defaultValue={inputs.X3}
                                          name="X3"
                                          onChange={set}/>
                                </Grid>

                              </Grid>
                            </Grid>











                            <Grid  item xs={12} md={2}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Fase 4"
                                      defaultValue={inputs.F4}
                                      name="F4"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={1}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Días 4"
                                      defaultValue={inputs.D4}
                                      name="D4"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={9}>
                              <Grid container spacing={1} justifyContent="end" >
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% N"
                                          defaultValue={inputs.N4}
                                          name="N4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% P"
                                          defaultValue={inputs.P4}
                                          name="P4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% K"
                                          defaultValue={inputs.K4}
                                          name="K4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Ca"
                                          defaultValue={inputs.Ca4}
                                          name="Ca4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mg"
                                          defaultValue={inputs.Mg4}
                                          name="Mg4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% S"
                                          defaultValue={inputs.S4}
                                          name="S4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Fe"
                                          defaultValue={inputs.Fe4}
                                          name="Fe4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Zn"
                                          defaultValue={inputs.Zn4}
                                          name="Zn4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mn"
                                          defaultValue={inputs.Mn4}
                                          name="Mn4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Cu"
                                          defaultValue={inputs.Cu4}
                                          name="Cu4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% B"
                                          defaultValue={inputs.B4}
                                          name="B4"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mo"
                                          defaultValue={inputs.X4}
                                          name="X4"
                                          onChange={set}/>
                                </Grid>

                              </Grid>
                            </Grid>








                            <Grid  item xs={12} md={2}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Fase 5"
                                      defaultValue={inputs.F5}
                                      name="F5"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={1}>
                              <Input
                                      fullWidth size="small"
                                      type="text"
                                      label="Días 5"
                                      defaultValue={inputs.D5}
                                      name="D5"
                                      onChange={set}/>
                            </Grid>
                            <Grid  item xs={12} md={9}>
                              <Grid container spacing={1} justifyContent="end" >
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% N"
                                          defaultValue={inputs.N5}
                                          name="N5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% P"
                                          defaultValue={inputs.P5}
                                          name="P5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% K"
                                          defaultValue={inputs.K5}
                                          name="K5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Ca"
                                          defaultValue={inputs.Ca5}
                                          name="Ca5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mg"
                                          defaultValue={inputs.Mg5}
                                          name="Mg5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% S"
                                          defaultValue={inputs.S5}
                                          name="S5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Fe"
                                          defaultValue={inputs.Fe5}
                                          name="Fe5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Zn"
                                          defaultValue={inputs.Zn5}
                                          name="Zn5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mn"
                                          defaultValue={inputs.Mn5}
                                          name="Mn5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Cu"
                                          defaultValue={inputs.Cu5}
                                          name="Cu5"
                                          onChange={set}/>
                                </Grid>
                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% B"
                                          defaultValue={inputs.B5}
                                          name="B5"
                                          onChange={set}/>
                                </Grid>

                                <Grid  item md={1}>
                                  <Input
                                          fullWidth size="small"
                                          type="text"
                                          label="% Mo"
                                          defaultValue={inputs.X5}
                                          name="X5"
                                          onChange={set}/>
                                </Grid>

                              </Grid>
                            </Grid>













                            <Grid  item xs={12} md={4} >
                              <Stack spacing={2} direction="row">
                                <Button type="submit"
                                        variant="contained">
                                    Guardar
                                </Button>
                                <Button onClick={()=>window.history.go(-1)}
                                        variant="outlined"
                                        >
                                    Cancelar
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
