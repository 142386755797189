import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import StateContext from '../../helpers/ContextState';

export default function SelectLabels({data,name,label,inputs,setInputs,placeholder,required,defaultValue,byId,children,disabled,sx}) {

  const context  = React.useContext(StateContext);
  const [age, setAge] = React.useState(defaultValue);

  const [reset, setReset]       = useState(false)

  const handleChange = (event: SelectChangeEvent) => {
    setInputs(event)
    setAge(event.target.value);

  };



  useEffect(()=>{
    setAge(defaultValue)
  },[defaultValue])

  useEffect(()=>{
    if (reset) {
      setReset(false)
    }
  },[reset])

  const onChange=(e)=>{

    if (children) {
      context.post("api/"+children.url,{id:e.target.value},false,false).then((response)=>{
        if (response.data) {
          let inputs_         =   {...children.input}
              inputs_         =   response.data
              children.setInput(inputs_)
        }
      })
    }

  }

  return (  <>
              {data && data.length && !reset>0?<>
                <FormControl sx={{ minWidth: "100%" }} disabled={disabled}>
                  <InputLabel id="demo-simple-select-helper-label" sx={sx}>
                    {label}
                  </InputLabel>
                  <Select
                    sx={sx}
                    required={required}
                    name={name}
                    labelId="demo-simple-select-helper-label"
                    id={name}
                    value={age}
                    label={label}
                    onChange={(e)=>{handleChange(e);onChange(e)}}
                  >
                    <MenuItem value="">
                      <em>{placeholder}</em>
                    </MenuItem>
                    {data.map((row,key)=>{
                      return <MenuItem key={key} value={!byId?row.id:row[byId]}>{row.label}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </>:<Typography variant="h6" ><Grid>No data in select</Grid></Typography>}
            </>
        );
}
