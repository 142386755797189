import React,{useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import StateContext from '../../helpers/ContextState';
import getCroppedImg from '../../helpers/cropImage'
import Cropper from './Cropper'
import avatar_img from '../../assets/images/avatar.png';

//const limit = 20

const App=({alt,src,endpoint,id,image})=>{
  const context               =   React.useContext(StateContext);
  const [img, setImg]         =   useState((image)?image:avatar_img);
  const [inputs, setInputs]   =   useState({});

  React.useEffect(()=>{
    if (image) {
      setImg(image)
    }
  },[image])

  const cropper=(event)=>{
    let file          =   event.target.files[0];
    let reader        =   new FileReader();

    reader.onload     =   function() {
      context.setModalShow({
                              footer:true,
                              footer_btn:{funct:subCrop,label:"Cortar"},
                              show:true,
                              size:"lg",
                              message:<div style={{height:"300px"}}><Cropper onCropComplete={onCropComplete} image={reader.result}/></div>
                            })

    }
    reader.readAsDataURL(file);
  }

  const onCropComplete=(croppedArea, croppedAreaPixels,image_)=>{
    let inputs_ =   inputs;
        inputs_.croppedArea       = croppedArea
        inputs_.croppedAreaPixels = croppedAreaPixels
        inputs_.image             = image_
        setInputs(inputs_)
  }

  const subCrop=()=>{
    async function croping(){
      let croppedImage = await getCroppedImg(
                                              inputs.image,
                                              inputs.croppedAreaPixels
                                            )
      let inputs_                   =   inputs;
          inputs_.image             =   croppedImage
      submitCrop(inputs_)
    }
    croping(this)
  }

  const submitCrop=(inputs_)=>{
    context.post("api/admin/users/uploadAvatarBase64",{ ...inputs_,
                                                        name:"avatar",
                                                        id:context.user.token},false,false).then((response)=>{
      if (response.data) {
        setImg(response.data)
        context.setModalShow(false)
        if (endpoint && id) {
          context.post(endpoint,{ avatar:img,id:id,save:"avatar_save",},false,false).then((response)=>{})
        }
      }
    })
  }



  return  <Stack direction="row" spacing={2} className="cam-content">
            <Avatar alt={alt}
                    sx={{ width: 100, height: 100 }}
                    src={img}
            />
            <input  accept='image/*'
                    id='icon-button-file'
                    type='file'
                    style={{margin:0}}
                    capture='environment'
                    onChange={cropper}/>
          </Stack>
}
export default App
