import * as React from 'react';
import {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Input    from '../common/Input';
import BtnSubmit from '../common/BtnSubmit';
import Button from '@mui/material/Button';
import StateContext from '../../helpers/ContextState';
import Select from '../common/Select';
import Loading from '../common/Loading01';

import {init,set} from '../../helpers/fninputs';
import ciudades     from '../../helpers/departamentos_municipios_2022.json';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";

import { useParams } from 'react-router-dom';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { NavLink } from "react-router-dom";

const btnSubmitId    =   'btnSubmitId'
let getInit

// const categorias    =   [
//                           {
//                             name:"Estructura",
//                             value:"Estructura"
//                           },
//                           {
//                             name:"Salud de Suelo",
//                             value:"Salud de Suelo"
//                           },
//                           {
//                             name:"Balance o Saturacion de Bases o Cationes",
//                             value:"Balance o Saturacion de Bases o Cationes"
//                           },
//                           {
//                             name:"Macro-Elementos Totales o Intercambiables",
//                             value:"Macro-Elementos Totales o Intercambiables"
//                           },
//                           {
//                             name:"Macro-elementos Disponibles",
//                             value:"Macro-elementos Disponibles"
//                           },
//                           {
//                             name:"Micro-elementos Disponibles",
//                             value:"Micro-elementos Disponibles"
//                           },
//                         ]

const inputs_default={
                        // name:"Laboratorio 1",
                        // contact:"Juan Lozada",
                        // direccion:"Dosquebradas",
                        // telefono:"3115000926",
                        // pais:"Colombia",
                      }

const App=()=>{
  const navigate                              = useNavigate();
  const context                               = React.useContext(StateContext);
  const [loading, setLoading]                 = useState(true);
  const [inputs, setInputs]                   = useState(inputs_default);
  const [departaments, setDepartaments]       = useState([]);
  const [cities, setCities]                   = useState([{name:"disabled",id:"0"}]);
  //const [categories, setCategories]           = useState([]);


  //const [subCategories, setSubCategories]     = useState([{name:"disabled",id:"0"}]);
  //const [unidades, setUnidades]               = useState([]);
  const [items, setItems]                     = useState([]);
  const [elements, setElements]               = useState([]);
  const [methods, setMethods]                 = useState([]);
  const [measures, setMeasures]               = useState([]);
  const [reset, setReset]                     = useState(false);
  const [groupSystem, setGroupSystem]         = useState([]);
  const [paises, setPaises]                   = useState([]);


  const params   =   useParams();

  init(inputs, setInputs)

  useEffect(() => {
    let mounted =   true
    orderDepartaments();
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  const orderDepartaments=()=>{
    setDepartaments(ciudades)
  }

  getInit=(mounted)=>{
    //return setLoading(false);
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data && response.data && response.data.data!==null) {
        //console.log(response.data.data);
        setInputs(response.data.data)
      }
      if (response.data && response.data && response.data.paises!==null) {
        //console.log(response.data.data);
        setPaises(response.data.paises)
      }
      setLoading(false)
      if (response.data && response.data.categories) {
        let categories_     = []
        let subCategories_  = []
        Object.entries(response.data.categories).map((row,key)=>{
          categories_.push({
                              name:row[0],
                              value:row[0]
                            })
          return subCategories_.push({name:row[0],items:row[1]})
        })
        //setCategories(categories_)
        //setSubCategories(subCategories_)
      }

      if (response.data && response.data.items!==null) {
        setItems(response.data.items)
      }
      if (response.data && response.data.elements!==null) {
        setElements(response.data.elements)
      }
      if (response.data && response.data.methods!==null) {
        setMethods(response.data.methods)
      }

      if (response.data && response.data.measures!==null) {
        setMeasures(response.data.measures)
      }

      if (response.data && response.data.groupSystem!==null) {

        let groupSystem_=[]
        Object.entries(response.data.groupSystem).map((row,key)=>{
          return groupSystem_.push({
                                      name:row[0],
                                      value:row[0],
                                      items:row[1]
                                    })
        })
        setGroupSystem(groupSystem_)
      }

    })
  }

  useEffect(() => {
    if (inputs.departamento && (cities.length===1 && cities[0].name==="disabled")) {
      const search  =   departaments.find((search)=>search.name===inputs.departamento)
      setCities(search.cities)
    }
  }, [inputs.departamento,cities,departaments]);

  useEffect(() => {
    if (context.onSubmit) {
      document.getElementById(btnSubmitId).click();
    }
  }, [context.onSubmit]);


  const onSubmitItems=(e)=>{
    setReset(true)
    let inputs_ = {...inputs}

    delete(inputs_.grupo)
    delete(inputs_.elemento)
    delete(inputs_.elemento_laboratorio)
    delete(inputs_.medidas)
    delete(inputs_.metodo)
    delete(inputs_.deficiente)
    delete(inputs_.bajo)
    delete(inputs_.bien)
    delete(inputs_.alto)
    delete(inputs_.exceso)
    delete(inputs_.id)
    delete(inputs_.item_id)

    e.preventDefault()
    context.post(false,{  ...inputs, items:true},false,true).then((response)=>{
      setInputs(inputs_)
      if (response && response.data) {
        setItems(response.data)
      }
      setReset(false)
    })
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    context.post(false,{  ...inputs,},false,true).then((response)=>{
      if (response && response.data && response.data && response.data.id) {
        navigate('/admin/setting/laboratories/'+response.data.id)
      }
    })
  }

  const onChange=(e)=>{
    let inputs__                =   {...inputs}
        inputs__[e.target.name] =   e.target.value

        console.log(inputs__);
        if (e.target.name==="departamento") {
          const result  = ciudades.find((search)=>search.name===e.target.value)
          setCities(result.cities)
        }

        if (e.target.name==="groupSystem") {
          const result  = groupSystem.find((search)=>search.name===e.target.value)
          //console.log(result);
          //setCities(result.cities)
        }

        let bajo  =   0
        let alto  =   0
        let medio =   0
        let init  =   0

        if (e.target.name==="bajo") {
          bajo  =   parseFloat(e.target.value)
          init  =   bajo/2
          inputs__["deficiente"]  = init
          inputs__["bien"]        = init + bajo
        }

        if (e.target.name==="alto") {
          bajo                    =   parseFloat(inputs__["bajo"]) || 0
          alto                    =   parseFloat(e.target.value)
          medio                   =   (parseFloat(bajo)+parseFloat(alto))/2
          inputs__["deficiente"]  =   parseFloat(bajo - (bajo/2))
          inputs__["bien"]        =   parseFloat(medio)
          inputs__["exceso"]      =   parseFloat(alto + medio)

        }

        setInputs(inputs__)

        // if (e.target.name==="categoria") {
        //   const result  = subCategories.find((search)=>search.name===e.target.value)
        //   if (result) {
        //     setUnidades(result.items)
        //   }
        // }

        // if (e.target.name==="elements") {
        //   const result  = elements.find((search)=>search.name===e.target.value)
        //
        //   // if (result) {
        //   //   setSubElements(result.items)
        //   // }
        // }

  }

  const handleDel=(row)=>{
    context.delete("api/admin/setting/laboratories/"+row.id,{  ...inputs },false,true).then((response)=>{
      if (response && response.data) {
        setItems(response.data)
      }
    })
  }

  const handleEdit=(row)=>{
    setReset(true)
    row.item_id   =   row.id;
    setInputs({...inputs,...row})
    setTimeout(()=>{      setReset(false)    }, 1000);
  }



  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>

              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid  item xs={12} md={10} >

                      <BtnSubmit id={btnSubmitId}/>
                        <Grid className="blocks">
                          <form onSubmit={onSubmit}>
                            <Grid className="header">Información básica del laboratorio</Grid>
                            <Grid className="body">
                              <Grid container spacing={2}>
                                  <Grid  item xs={12} md={9}>
                                    <Input
                                            fullWidth
                                            size="small"
                                            type="text"
                                            label="Nombre Laboratorio"
                                            defaultValue={inputs.name}
                                            name="name"
                                            onChange={set}
                                            />
                                  </Grid>
                                  <Grid  item xs={12} md={3}>
                                    <Input
                                            size="small"
                                            fullWidth
                                            type="text"
                                            label="Contacto"
                                            defaultValue={inputs.contact}
                                            name="contact"
                                            onChange={set}
                                            />
                                  </Grid>
                                  <Grid  item xs={12} md={9}>
                                    <Input
                                            size="small"
                                            fullWidth
                                            type="text"
                                            label="Dirección"
                                            defaultValue={inputs.direccion}
                                            name="direccion"
                                            onChange={set}
                                            />
                                  </Grid>
                                  <Grid  item xs={12} md={3}>
                                    <Input
                                            size="small"
                                            fullWidth
                                            type="text"
                                            label="Teléfono"
                                            defaultValue={inputs.telefono}
                                            name="telefono"
                                            onChange={set}
                                            />
                                  </Grid>
                                  <Grid  item xs={12} md={3} sx={{mb:3}}>
                                    <Select data={paises}
                                            name="pais"
                                            required={true}
                                            defaultValue={inputs.pais}
                                            placeholder='País'
                                            inputs={inputs}
                                            setInputs={set}
                                            label="País"
                                            value={inputs.pais} />
                                  </Grid>
                                  <Grid  item xs={12} md={3} sx={{mb:3}}>
                                    <Stack spacing={2} direction="row">
                                      <Button type="submit"
                                              variant="contained">
                                          Guardar
                                      </Button>
                                      <Button variant="outlined" component={NavLink} to="/admin/setting/laboratories">
                                          Cancelar
                                      </Button>
                                    </Stack>
                                  </Grid>
                              </Grid>
                            </Grid>
                        </form>

                        {
                          params.id!=="0"?<>
                            {!reset?<>
                              <form onSubmit={onSubmitItems}>
                                <Grid container spacing={2}>
                                  <Grid  item xs={12}>
                                    <Grid className="header">Mediciones del laboratorio</Grid>
                                  </Grid>
                                  <Grid  item xs={12} md={2}>
                                    <Select data={groupSystem}
                                            name="groupSystem"
                                            required
                                            fullWidth
                                            sx={{m:0,}}
                                            defaultValue={inputs.groupSystem}
                                            placeholder='Grupo sistema'
                                            inputs={inputs}
                                            setInputs={onChange}
                                            label="Grupo sistema"/>
                                  </Grid>
                                  <Grid  item xs={12} md={2}>
                                    <Input
                                            fullWidth
                                            type="text"
                                            required
                                            label="Grupo"
                                            defaultValue={inputs.grupo}
                                            name="grupo"
                                            onChange={set}/>

                                  </Grid>
                                  <Grid  item xs={12} md={2}>
                                    <Select data={elements}
                                            name="elemento"
                                            required
                                            sx={{
                                                  m:0,
                                                  //maxWidth:180
                                                }}
                                            defaultValue={inputs.elemento}
                                            placeholder='Elemento'
                                            inputs={inputs}
                                            setInputs={onChange}
                                            label="Elemento"/>
                                  </Grid>
                                  <Grid  item xs={12} md={2}>
                                    <Input
                                            fullWidth
                                            type="text"
                                            required
                                            label="Elemento laboratorio Ej: Arcillita"
                                            defaultValue={inputs.elemento_laboratorio}
                                            name="elemento_laboratorio"
                                            onChange={set}/>

                                  </Grid>
                                  <Grid  item xs={12} md={2}>
                                    <Select data={measures}
                                            name="medidas"
                                            required
                                            defaultValue={inputs.medidas}
                                            sx={{m:0}}
                                            placeholder='Unidades'
                                            inputs={inputs}
                                            setInputs={onChange}
                                            label="Unidades"/>
                                  </Grid>
                                  <Grid  item xs={12} md={2}>
                                    <Select data={methods}
                                            name="metodo"
                                            required
                                            defaultValue={inputs.metodo}
                                            sx={{m:0}}
                                            placeholder='Método'
                                            inputs={inputs}
                                            setInputs={onChange}
                                            label="Método"/>
                                  </Grid>
                                  <Grid  item xs={12}>
                                    Valores Comparativos
                                  </Grid>
                                  <Grid  item xs={12} md={1}>
                                    <Input
                                            fullWidth
                                            type="text"
                                            disabled
                                            label="Deficiente"
                                            value={inputs.deficiente}
                                            name="deficiente"
                                            onChange={set}/>

                                  </Grid>
                                  <Grid  item xs={12} md={1}>
                                    <Input
                                            fullWidth
                                            type="text"
                                            required
                                            label="Bajo"
                                            defaultValue={inputs.bajo}
                                            name="bajo"
                                            onChange={onChange}/>

                                  </Grid>
                                  <Grid  item xs={12} md={1}>
                                    <Input
                                            fullWidth
                                            type="text"
                                            disabled
                                            label="Bien"
                                            value={inputs.bien}
                                            name="bien"
                                            onChange={set}/>

                                  </Grid>
                                  <Grid  item xs={12} md={1}>
                                    <Input
                                            fullWidth
                                            type="text"
                                            required
                                            label="Alto"
                                            defaultValue={inputs.alto}
                                            name="alto"
                                            onChange={onChange}/>

                                  </Grid>
                                  <Grid  item xs={12} md={1}>
                                    <Input
                                            fullWidth
                                            type="text"
                                            disabled
                                            label="Exceso"
                                            value={inputs.exceso}
                                            name="exceso"
                                            onChange={set}/>

                                  </Grid>
                                  <Grid  item xs={12} md={1}>
                                    <Button type="submit"
                                            variant="contained">
                                        +
                                    </Button>
                                  </Grid>
                                </Grid>
                              </form>
                            </>:false}
                        </>:false
                      }

                    </Grid>
                    {items.length>0?<>

                      <Grid>
                        <Grid className="blocks">
                          <Grid className="header">Items</Grid>
                          <Grid className="body">
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align={"left"}>
                                        Grupo Sistema
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        Grupo
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        Elemento
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        Laboratorio
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        Unidad
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        Método
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        Deficiente
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        Bajo
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        Bien
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        Alto
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        Exceso
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        Acción
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {items.map((row,key)=>{
                                    return  <TableRow key={key}>
                                              <TableCell align={"left"}>
                                                  {row.groupSystem}
                                              </TableCell>
                                              <TableCell align={"left"}>
                                                  {row.grupo}
                                              </TableCell>
                                              <TableCell align={"left"}>
                                                  {row.elemento}
                                              </TableCell>
                                              <TableCell align={"left"}>
                                                  {row.elemento_laboratorio}
                                              </TableCell>
                                              <TableCell align={"left"}>
                                                  {row.medidas}
                                              </TableCell>
                                              <TableCell align={"center"}>
                                                  {row.metodo}
                                              </TableCell>
                                              <TableCell align={"center"}>
                                              {row.deficiente}
                                              </TableCell>
                                              <TableCell align={"center"}>
                                                  {row.bajo}
                                              </TableCell>
                                              <TableCell align={"center"}>
                                                  {row.bien}
                                              </TableCell>
                                              <TableCell align={"center"}>
                                                  {row.alto}
                                              </TableCell>
                                              <TableCell align={"center"}>
                                                  {row.exceso}
                                              </TableCell>
                                              <TableCell align={"center"}>
                                                <EditIcon className="cursor-pointer" onClick={()=>handleEdit(row)}/>
                                                <DeleteIcon className="cursor-pointer" onClick={()=>handleDel(row)}/>
                                              </TableCell>
                                            </TableRow>
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Grid>

                      </>:false}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>}
          </>
}
export default App
