import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import StateContext from '../../helpers/ContextState';
import Typography from '@mui/material/Typography';
import Loading from '../common/Loading01';
import successcontact from '../../assets/images/successcontact.jpg'
import img1 from '../../assets/images/frontend.jpg'
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import {
          inputs,
          useInputs
        } from '../../helpers/useInputs';

let tokens  =   false

const App=()=>{
  const context               =   React.useContext(StateContext);
  const [alert,setAlert]      =   React.useState(false)

  const handleReCaptchaVerify=(response)=>{
    tokens=response
  }

  const handleSubmit=(e)=>{
    e.preventDefault()
    if (!tokens) {
      context.setModalShow({
                      show:true,
                      message:"Es necesario saber si no eres un robot",
                      size:""
                    }
                  )
      return false;
    }
    context.post("api/contact",{...inputs,token:tokens},false,false,true).then((response)=>{
      if (response.data) {
        setAlert(response.data)
      }
    })
  }


  return  <GoogleReCaptchaProvider reCaptchaKey="6LfFvF4hAAAAANZO7S9BScDAdbOyFTUBioU2gjQz" >
            <GoogleReCaptcha onVerify={handleReCaptchaVerify}/>
            {
              /*
              <Carousel data={gallery}/>
              */
            }

            <Grid container justifyContent="center" spacing={2} sx={{mb:0}} className="bg-white">
              {
                !alert?<>
                  <Grid item xs={11} sx={{ mt: {
                                                  xs:10,
                                                  md:14
                                                },
                                          }} >

                      <Typography component="h4" className="text-center h4 mb-2 uppercase">
                        ¿Quieres conocer un poco más? <b>escríbenos</b> acá debajo.
                      </Typography>

                      <Grid className="text-complete">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4} sx={{p:4}}>
                            <img src={img1} alt={"programandoweb"} className="img-fluid"/>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <form id="formulario" onSubmit={handleSubmit}>
                                <Box sx={{ mt: 1 }}>
                                  <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="email"
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={useInputs}
                                  />
                                  <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="text"
                                    id="name"
                                    label="Nombre y apellidos"
                                    name="name"
                                    onChange={useInputs}
                                  />
                                  <TextField
                                    multiline
                                    rows={4}
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    id="message"
                                    label="Escribe tu inquietud..."
                                    name="message"
                                    onChange={useInputs}
                                  />
                                  <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}> Enviar </Button>
                                </Box>
                              </form>
                          </Grid>
                        </Grid>
                      </Grid>
                  </Grid>
                </>:alert?<>
                  <Grid item xs={11} sx={{ mt: {
                                                xs:10,
                                                md:14
                                              },
                                        }} >
                          <Grid container spacing={2}>
                            <Grid item  xs={12} md={7}>
                                <h1>Excelente</h1>
                                <p>{alert.label||"  Efectivamente pudimos entregar tu mensaje al departamento correspondiente,pronto nos comunicaremos contigo."}</p>
                                <p>Por lo pronto te invitamos a conocer más de nuestro trabajo, también puedes comunicarte via whataspp, arriba a la derecha está el ícono.</p>
                            </Grid>
                            <Grid item  xs={12} md={5}>
                              <img src={successcontact} alt="programandoweb" className="img-fluid"/>
                            </Grid>
                          </Grid>
                        </Grid>
                </>:<>
                  <Loading/>
                </>
              }
            </Grid>
          </GoogleReCaptchaProvider>
}
export default App;
