import React from 'react';
import Grid from '@mui/material/Grid';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Button   from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import Table from '../common/Table';

const title =   "Texturas suelos"

const td  = [
              {
                label:"Elemento",
                value:"label",
                align:"left"
              },
              {
                label:"Arcilla Min",
                value:"arcilla_min",
                align:"center"
              },
              {
                label:"Arcilla Max",
                value:"arcilla_max",
                align:"center"
              },
              {
                label:"Limo Min",
                value:"limo_min",
                align:"center"
              },
              {
                label:"Limo Max",
                value:"limo_max",
                align:"center"
              },
              {
                label:"Arena Min",
                value:"arena_min",
                align:"center"
              },
              {
                label:"Arena Max",
                value:"arena_max",
                align:"center"
              },
              {
                label:"Densaidad aparente",
                value:"densidad_aparente",
                align:"center"
              },
              {
                label:"Compactación",
                value:"compactacion",
                align:"center"
              },
              {
                label:"Acción",
                align:"center",
                items:["edit"]
              },
            ]

const App=({usePrivileges,endpoint})=>{
  return    <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12}  sx={{
                                            mt:{ xs: 1, md: 0 },
                                            mb: { xs: 1, md: 0 },
                                            p:{ xs: 1, md: 0 },
                                          }}>
                    <Table component={<Button variant="outlined" startIcon={<ControlPointIcon/>} size="small" fullWidth sx={{ mb: { xs: 1, md: 0 },  }} component={NavLink} to={"0"}>Agregar {title}</Button>} td={td} endpoint={endpoint?endpoint:false}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
}
export default App
