import React from 'react';
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Button   from '@mui/material/Button';

const App=({data,xs,md})=>{

  //const [loading, setLoading] =   React.useState(true);

  return  <Grid container justifyContent="center">
            <Grid item xs={12} className="bg-white" sx={{p:2}}>
              <Typography component="h1" className="text-center title-main">
                <b>Programa de afiliados</b>
              </Typography>
              <Grid container justifyContent="center" spacing={5} sx={{mt:0}}>
                <Grid item xs={8}>
                  <Typography component="p" className="text-center p-main" sx={{mb:5}}>
                  </Typography>
                  <Grid  className="text-center">
                    <Button variant={"contained"} color="danger">Conoce más</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} className="bg-white" sx={{p:4}}>
              <Grid container justifyContent="center" spacing={5}>
                <Grid item xs={12}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="194"
                      image="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png"
                      alt="Programandoweb"
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
