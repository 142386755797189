import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import img from '../../assets/images/404_animation.gif';
const App=()=>{
  return  <Grid container spacing={0} justifyContent="center">
            <Grid item xs={12} md={4} sx={{mt:2}}>
              <Paper sx={{ p: 2, }} >
                <img src={img} alt="Programandoweb" className="img-fluid"/>
                <h1 className="text-center">404</h1>
                <h6 className="text-center">Lo sentimos, este módulo o sección no existe</h6>
              </Paper>
            </Grid>
          </Grid>
}
export default App
