/*components*/
import Home from '../components/freezone/web';
import Setting from '../components/setting';
import SettingContacts from '../components/setting/SettingContacts';
import SettingContactsAdd from '../components/setting/SettingContactsAdd';
import HomeAdmin from '../components/home/HomeAdmin';
import Users from '../components/user/UsersGet';
import UsersEdit from '../components/user/UsersEdit';
import UserExit from '../components/user/UserExit';
/*icons*/
import HomeIcon from '@mui/icons-material/Home';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import BuildIcon from '@mui/icons-material/Build';
import CmsContent from '../components/web/CmsContent';
import PwaContent from '../components/web/PwaContent';
import EcommerceContent from '../components/web/EcommerceContent';
import WebsiteContent from '../components/web/WebsiteContent';
import DesarrolloAMedida from '../components/web/DesarrolloAMedida';
import Contact from '../components/web/Contact';
import Community from '../components/web/Community';
import Langs from '../components/setting/Langs';
import Laboratories from '../components/setting/Laboratories';
import LaboratoryAdd from '../components/setting/LaboratoryAdd';
import Crops from '../components/setting/Crops';
import CropsAdd from '../components/setting/CropsAdd';
import Elements from '../components/setting/Elements';
import ElementsAdd from '../components/setting/ElementsAdd';

import Metodos from '../components/setting/Metodos';
import MetodosAdd from '../components/setting/MetodosAdd';

import Medidas from '../components/setting/Medidas';
import MedidasAdd from '../components/setting/MedidasAdd';


import Equivalencias from '../components/setting/Equivalencias';
import EquivalenciasAdd from '../components/setting/EquivalenciasAdd';

import Recomendaciones from '../components/setting/Recomendaciones';
import RecomendacionesAdd from '../components/setting/RecomendacionesAdd';

import TexturasSuelos from '../components/setting/TexturasSuelos';
import TexturasSuelosAdd from '../components/setting/TexturasSuelosAdd';

//import Finances from '../components/finances';

/*
  name: nombre a mostrar en el menú
  slug: uri
  component: component
  menu: si se muestra o no en el menú
  ico:ico
*/


import CoursePublic from '../components/freezone/CoursePublic';
import ListFields from '../components/fields/ListFields';
import FieldsAdd from '../components/fields/FieldsAdd';


export const modules_protect  = [
  {
    name:"Home",
    slug:"/",
    component:HomeAdmin,
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Elementos y grupos",
    slug:"/setting/elements",
    component:Elements,
    arraySearch:["/setting/elements/edit","/setting/elements/edit/:id",],
    edit:"/setting/elements/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Equivalencias",
    slug:"/setting/equivalencias",
    component:Equivalencias,
    arraySearch:["/setting/equivalencias/edit","/setting/equivalencias/edit/:id",],
    edit:"/setting/equivalencias/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Editar Equivalencias",
    slug:"/setting/equivalencias/:id",
    component:EquivalenciasAdd,
    arraySearch:["/setting/equivalencias","/setting/equivalencias/:id",],
    edit:"/setting/equivalencias/create",
    menu:false,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Medidas",
    slug:"/setting/medidas",
    component:Medidas,
    arraySearch:["/setting/medidas/edit","/setting/medidas/edit/:id",],
    edit:"/setting/medidas/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Métodos",
    slug:"/setting/metodos",
    component:Metodos,
    arraySearch:["/setting/metodos/edit","/setting/metodos/edit/:id",],
    edit:"/setting/metodos/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Editar Métodos",
    slug:"/setting/metodos/:id",
    component:MetodosAdd,
    arraySearch:["/setting/metodos","/setting/metodos/:id",],
    edit:"/setting/metodos/create",
    menu:false,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"N Recomendaciones",
    slug:"/setting/recomendaciones",
    component:Recomendaciones,
    arraySearch:["/setting/recomendaciones/edit","/setting/recomendaciones/edit/:id",],
    edit:"/setting/recomendaciones/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Editar N Recomendaciones",
    slug:"/setting/recomendaciones/:id",
    component:RecomendacionesAdd,
    arraySearch:["/setting/recomendaciones","/setting/recomendaciones/:id",],
    edit:"/setting/recomendaciones/create",
    menu:false,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },

  {
    name:"Texturas suelos",
    slug:"/setting/texturasSuelos",
    component:TexturasSuelos,
    arraySearch:["/setting/texturasSuelos/edit","/setting/texturasSuelos/edit/:id",],
    edit:"/setting/texturasSuelos/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Editar Texturas suelos",
    slug:"/setting/texturasSuelos/:id",
    component:TexturasSuelosAdd,
    arraySearch:["/setting/texturasSuelos","/setting/texturasSuelos/:id",],
    edit:"/setting/texturasSuelos/create",
    menu:false,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Análisis",
    slug:"/fields",
    component:ListFields,
    menu:true,
    private:true,
    ico:<NewspaperIcon/>,
    items:[],
  },
  {
    name:"Fincas",
    slug:"/fincas",
    component:ListFields,
    menu:true,
    private:true,
    ico:<NewspaperIcon/>,
    items:[],
  },
  {
    name:"Editar Fincas",
    slug:"/fincas/:id",
    component:FieldsAdd,
    arraySearch:["/fincas","/fincas/:id",],
    edit:"/fincas/create",
    menu:false,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Cultivos",
    slug:"/setting/crops",
    component:Crops,
    arraySearch:["/setting/crops/edit","/setting/crops/edit/:id",],
    edit:"/setting/crops/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Laboratorios",
    slug:"/setting/laboratories",
    component:Laboratories,
    arraySearch:["/setting/laboratories/edit","/setting/laboratories/edit/:id",],
    edit:"/setting/laboratories/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Paises",
    slug:"/setting/paises",
    component:Elements,
    arraySearch:["/setting/paises/edit","/setting/paises/edit/:id",],
    edit:"/setting/paises/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Departamentos",
    slug:"/setting/departamentos",
    component:Elements,
    arraySearch:["/setting/departamentos/edit","/setting/departamentos/edit/:id",],
    edit:"/setting/departamentos/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Municipios",
    slug:"/setting/municipios",
    component:Elements,
    arraySearch:["/setting/elements/edit","/setting/elements/edit/:id",],
    edit:"/setting/municipios/create",
    menu:true,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Admin Main",
    slug:"/main",
    endpoint:"/main",
    component:HomeAdmin,
    menu:false,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Admin Home",
    slug:"/home",
    endpoint:"/admin/users",
    component:Users,
    menu:false,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Usuarios",
    slug:"/users",
    component:Users,
    arraySearch:["/users/edit","/users/edit/:id",],
    edit:"/users/edit",
    menu:true,
    private:true,
    ico:<GroupAddIcon/>,
    items:[

    ],
  },
  {
    name:"Editar usuario",
    slug:"/users/:id/",
    component:UsersEdit,
    arraySearch:["/users","/users/:id",],
    edit:"/users/:id",
    menu:false,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },

  {
    name:"Editar Artículos",
    slug:"/fields/:id",
    component:FieldsAdd,
    arraySearch:["/fields/edit","/fields/edit/:id",],
    edit:"/fields/create",
    menu:false,
    private:true,
    ico:<HomeIcon/>,
    items:[],
  },
  {
    name:"Configuración",
    slug:"/setting",
    component:Setting,
    menu:false,
    private:true,
    ico:<BuildIcon/>,
    items:[
      {
        name:"Básica",
        slug:"/setting",
        component:Setting,
        arraySearch:["/setting/edit","/setting/edit/:id",],
        edit:"/setting/create",
        menu:true,
        private:true,
        ico:<HomeIcon/>,
        items:[],
      },
      {
        name:"Editar Cultivos",
        slug:"/setting/crops/:id",
        component:CropsAdd,
        arraySearch:["/setting/crops","/setting/crops/:id",],
        edit:"/setting/crops/create",
        menu:false,
        private:true,
        ico:<HomeIcon/>,
        items:[],
      },

      {
        name:"Editar Elementos",
        slug:"/setting/elements/:id",
        component:ElementsAdd,
        arraySearch:["/setting/elements","/setting/elements/:id",],
        edit:"/setting/elements/create",
        menu:false,
        private:true,
        ico:<HomeIcon/>,
        items:[],
      },
      {
        name:"Editar Medidas",
        slug:"/setting/medidas/:id",
        component:MedidasAdd,
        arraySearch:["/setting/medidas","/setting/medidas/:id",],
        edit:"/setting/medidas/create",
        menu:false,
        private:true,
        ico:<HomeIcon/>,
        items:[],
      },

      {
        name:"Editar Laboratorio",
        slug:"/setting/laboratories/:id",
        component:LaboratoryAdd,
        arraySearch:["/setting/laboratories","/setting/laboratories/:id",],
        edit:"/setting/laboratories/create",
        menu:false,
        private:true,
        ico:<HomeIcon/>,
        items:[],
      },
      {
        name:"Lenguajes",
        slug:"/setting/lang",
        component:Langs,
        arraySearch:["/setting/lang/edit","/setting/lang/edit/:id",],
        edit:"/setting/lang/create",
        menu:false,
        private:true,
        ico:<HomeIcon/>,
        items:[],
      },
      {
        name:"Contactos",
        slug:"/setting/contacts",
        component:SettingContacts,
        arraySearch:["/setting/contacts/edit","/setting/contacts/edit/:id",],
        edit:"/setting/contacts/create",
        menu:false,
        private:true,
        ico:<HomeIcon/>,
        items:[],
      },
      // {
      //   name:"Agregar Contacto",
      //   slug:"/setting/contacts/create",
      //   component:SettingContactsAdd,
      //   arraySearch:["/setting/contacts","/setting/contacts/:id",],
      //   edit:"/setting/contacts/create",
      //   menu:true,
      //   private:true,
      //   ico:<HomeIcon/>,
      //   items:[],
      // },
      {
        name:"Editar Contacto",
        slug:"/setting/contacts/:id",
        component:SettingContactsAdd,
        arraySearch:["/setting/contacts","/setting/contacts/:id",],
        edit:"/setting/contacts/create",
        menu:false,
        private:true,
        ico:<HomeIcon/>,
        items:[],
      },
    ]
  },
]

export const modules  = [
  {
    name:"home",
    slug:"/",
    component:Home,
    menu:false,
    private:false,
    ico:<HomeIcon/>,
    items:[],
  },{
    name:"Programandoweb",
    slug:"/cms_programandoweb/:slug",
    component:CmsContent,
    menu:false,
    private:false,
    ico:false,
    items:[],
  },{
    name:"Programandoweb",
    slug:"/help/cms_programandoweb/:slug",
    component:CmsContent,
    menu:false,
    private:false,
    ico:false,
    items:[],
  },{
    name:"Cursos",
    slug:"/course/:slug",
    component:CoursePublic,
    menu:false,
    private:false,
    ico:false,
    items:[],
  },{
    name:"Academias",
    slug:"/help",
    component:Community,
    menu:true,
    private:false,
    ico:<HomeIcon/>,
    items:[],
  },{
    name:"Beneficios",
    slug:"/productos_y_servicios",
    component:Community,
    menu:true,
    private:false,
    ico:<HomeIcon/>,
    items:[
      {
        name:"PWA Páginas progresivas",
        slug:"/pwa-paginas-progresivas",
        component:PwaContent,
        menu:true,
        private:false,
        ico:<HomeIcon/>,
        items:[],
      },{
        name:"Páginas Web",
        slug:"/paginas-web",
        component:WebsiteContent,
        menu:true,
        private:false,
        ico:<HomeIcon/>,
        items:[],
      },{
        name:"Carros de compra",
        slug:"/ecommerce",
        component:EcommerceContent,
        menu:false,
        private:false,
        ico:<HomeIcon/>,
        items:[],
      },{
        name:"Desarrollo a la medida",
        slug:"/desarrollo-a-la-medida",
        component:DesarrolloAMedida,
        menu:true,
        private:false,
        ico:<HomeIcon/>,
        items:[],
      }
    ],
  },{
    name:"Paquetes",
    slug:"/contact",
    component:Contact,
    menu:true,
    private:false,
    ico:<HomeIcon/>,
    items:[],
  },{
    name:"Programa de afiliados",
    slug:"/contact",
    component:Contact,
    menu:true,
    private:false,
    ico:<HomeIcon/>,
    items:[],
  }
  ,{
    name:"Por qué Click Conexión",
    slug:"/contact",
    component:Contact,
    menu:true,
    private:false,
    ico:<HomeIcon/>,
    items:[],
  }
  ,{
    name:"Contacto",
    slug:"/contact",
    component:Contact,
    menu:false,
    private:false,
    ico:<HomeIcon/>,
    items:[],
  }
]

export const menu_user  = [
  {
    name:"Mi cuenta",
    slug:"/home",
    component:Users,
    menu:false,
    private:true,
    ico:false,
    items:[],
  },
  {
    name:"Area de usuarios",
    slug:"/",
    component:Users,
    menu:false,
    private:false,
    ico:false,
    items:[],
  },
  {
    name:"Cerrar Sesión",
    slug:"/account/exit",
    module:"user",
    method:"exit",
    component:UserExit,
    menu:true,
    private:true,
    ico:false,
    items:[],
  },
]
