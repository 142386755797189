import Grid from '@mui/material/Grid';
import Table from '../common/Table';


const td  = [
              {
                label:"Nombres",
                value:"name",
                align:"left"
              },
              {
                label:"Celular",
                value:"celular",
                align:"left"
              },
              {
                label:"Email",
                value:"email",
                align:"left"
              },
              {
                label:"Perfil",
                value:"email",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["edit","destroy"]
              },
            ]

const App = ()=>{
  return  <Grid  container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={12}  sx={{
                                          mt:{ xs: 1, md: 0 },
                                          mb: { xs: 1, md: 0 },
                                          p:{ xs: 1, md: 0 },
                                        }}>
                  <Table td={td}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
