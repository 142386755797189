import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper    from '@mui/material/Paper';
import Avatar   from '../common/Avatar';
import Input    from '../common/Input';
import Button from '@mui/material/Button';
import Select from '../common/Select';
import StateContext from '../../helpers/ContextState';
import Loading from '../common/Loading';
import {init,set,uri,getRandomInt} from '../../helpers/fninputs';
import Chips from '../common/Chips';
import { useLocation } from "react-router-dom";


let getInit
const pass  =   ("abc_"+getRandomInt(100000,900000)).substr(0,10)

const App=()=>{
  //const params                    =   useParams();
  //const navigate                  =   useNavigate();
  const location                        =   useLocation();
  const context                         =   React.useContext(StateContext);
  const [loading, setLoading]           =   React.useState(true);
  const [inputs, setInputs]             =   React.useState({password:pass});
  const [roles, setRoles]               =   React.useState([]);
  const [rolesInputs, setRolesInputs]   =   React.useState([]);

  init(inputs, setInputs)

  React.useEffect(() => {
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  }, []);

  //getRandomInt(3)


  getInit=(mounted)=>{

    context.get(false,{...context.user},false,false).then((response)=>{

      if (response.data && response.data.data) {
        setInputs(response.data.data)
      }

      if (response.data && response.data.data && response.data.data.roles) {
        setRolesInputs(response.data.data.roles)
      }

      if (response.data && response.data.roles)  {
        setRoles(response.data.roles)
      }

      setLoading(false)

    })

  }

  const onSubmit=(e)=>{
    e.preventDefault()
    context.post(uri(location.pathname),{...inputs,selectedRoles:JSON.stringify(rolesInputs)},false,true).then((response)=>{
      if (response && response.data && response.data.token) {
        window.history.go(-1);
      }
    })
  }


  return  <>{loading?<Loading height={'60vh'}/>:<Grid sx={{ flexGrow: 1 }} container spacing={0}>
            {Object.entries(inputs).length >0 || location.pathname.includes("/0")?<>
              <Grid item xs={12} sx={{ mt: 1, }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={11} sm={11} md={3} >
                    <Paper sx={{ p: 2, }} >
                      <Grid style={{display:'flex',justifyContent:'center',alignItems:'center',}}><Avatar image={inputs.avatar} endpoint={uri(location.pathname)} id={inputs.token}/></Grid>
                      <Grid style={{display:'flex',justifyContent:'center'}}>
                        <Typography variant="h6" >
                          <b>{inputs.name}</b>
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid  item xs={11} sm={11} md={8} >
                    <form onSubmit={onSubmit}>
                      <Paper sx={{ p: 2, }}>
                        <Input  margin="normal"
                                required
                                fullWidth
                                type="text"
                                id="name"
                                label="Nombres y Apellidos"
                                name="name"
                                autoComplete="email"
                                autoFocus
                                defaultValue={inputs.name}
                                onChange={set}/>
                        <Grid container spacing={1}>
                          <Grid  item xs={12} md={3}>
                            <Input  margin="normal"
                                    fullWidth
                                    type="text"
                                    label="Teléfono celular"
                                    name="celular"
                                    defaultValue={inputs.celular}
                                    onChange={set}/>
                          </Grid>
                          <Grid  item xs={12} md={3}>
                            <Input  margin="normal"
                                    type="email"
                                    required
                                    fullWidth
                                    label="Correo electrónico"
                                    name="email"
                                    autoComplete="email"
                                    defaultValue={inputs.email}
                                    onChange={set}/>
                          </Grid>
                          <Grid  item xs={12} md={3}>
                            <Input  margin="normal"
                                    disabled={(!inputs.email)?false:true}
                                    type="password"
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    defaultValue={(!inputs.email)?pass:""}
                                    onChange={set}/>
                          </Grid>
                          <Grid  item xs={12} md={3} sx={{mt:1}}>
                            <Select data={roles}

                                    disabled={inputs.rol?true:false}
                                    name="rol"
                                    byId={"name"}
                                    defaultValue={inputs.rol}
                                    placeholder='Tipo de usuario'
                                    inputs={inputs}
                                    setInputs={set}
                                    label="Rol"
                                    value={inputs.rol} />
                          </Grid>

                          <Grid item xs={12}>
                            <Chips data={rolesInputs} setData={setRolesInputs}/>
                          </Grid>

                          <Grid  item xs={12}>
                            <Button type="submit"
                                    variant="contained"
                                    sx={{ ml: 1 }}>
                              Guardar
                            </Button>
                            <Button type="button"
                                    onClick={()=>window.history.go(-1)}
                                    variant="outlined"
                                    sx={{ ml: 1 }}>
                              Cancelar
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </>:false}
          </Grid>}
        </>
}
export default App
