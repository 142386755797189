import React from 'react';
//import { useState } from 'react';
import StateContext from '../../helpers/ContextState';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {
          inputs,
          useInputs
        } from '../../helpers/useInputs';

const Login=()=>{
  let navigate  = useNavigate();
  const context = React.useContext(StateContext);

  const handleSubmit=(e)=>{
    e.preventDefault()
    context.post(false,inputs,false,true).then((response)=>{
      if (response.data && response.data.token) {
        //navigate("/auth/register/continue/"+response.data.token)
        navigate("/auth/login/"+response.data.token)
      }
    })
  }

  return <>
          <Typography component="h1" variant="h5">
            Registro de usuario
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              type="text"
              id="nombre"
              label="Nombre y apellido"
              name="name"
              onChange={useInputs}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type="email"
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              onChange={useInputs}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={useInputs}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}> Registrarse </Button>
            <Grid container>
              <Grid item xs>
                <NavLink to="/auth/login" variant="body2">
                  <Typography>
                    Iniciar sesión
                  </Typography>
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </>
}

export default Login
