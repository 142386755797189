export const get=(index)=>{
  const get = localStorage.getItem(index);
  if (get!==null && get!=='' && get!==undefined && get!=="undefined")  {
    return JSON.parse(get);
  }else if (get==="undefined") {
    clear(index);
    return {}
  } else {
    return {}
  }
}

export const set=(index,object)=>{
  localStorage.setItem(index,JSON.stringify(object));
}

export const clear=()=>{
  localStorage.clear();
}

export const useStore=()=>{
  return  {
            clear:clear,
            set:set,
            get:get,
          }
}
