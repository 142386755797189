import { createTheme } from '@mui/material/styles';
import { green,grey } from '@mui/material/colors';
// export const theme = createTheme({
//   typography: {
//     fontFamily: [
//       'Open Sans',
//       'sans-serif',
//     ].join(','),
//   },
//   palette: {
//     primary: {
//       main: '#9c27b0',
//     },
//     // secondary: {
//     //   main: '#ddd',
//     // },
//   },
//
// });

export const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem',
          //color:"#333",
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Raleway',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: green[900],
      contrastText: '#ffffff',
    },
    secondary: {
      main: green[900],
    },
    danger: {
      main: grey[900],
      contrastText: '#ffffff',
    },
  },

});
