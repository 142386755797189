import * as React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
//import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import ReactHtmlParser from 'react-html-parser';
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';


export default function RecipeReviewCard({data}) {
  return (
    <Card sx={{ maxWidth: "100%" }} className="scale">
      <div  className="uppercase title_item2 p-1 ">
        {data.subcategory}
      </div>
      <CardMedia
        component="img"
        height="194"
        image={data.image_fullurl}
        alt={data.title}
      />
      <CardContent>
        <div  className="uppercase title_item3">
          {data.title}
        </div>
        <div  className="title_item4">
          { ReactHtmlParser((data.summary!=='')?data.summary:data.content) }
        </div>
        <Grid className="text-end">
          {data.slug!=='#'?  <Grid className="slider_item_btn mt-2">
                              <Button variant="contained" fullWidth component={NavLink} to={data.slug}>Me interesa</Button>
                            </Grid>:false}
        </Grid>
      </CardContent>
    </Card>
  );
}
