import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Loading from '../common/Loading01';
import StateContext from '../../helpers/ContextState';
import Input    from '../common/Input';
import {init,set,uri} from '../../helpers/fninputs';
import Button from '@mui/material/Button';
import { useLocation } from "react-router-dom";

let getInit=false

const App=()=>{
  const location              =   useLocation();
  const context               =   React.useContext(StateContext);
  const [loading,setLoading]  =   React.useState(false)
  const [inputs, setInputs]   =   React.useState({});

  init(inputs, setInputs)

  getInit = ()=>{
    setLoading(true)
    context.get(false,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setInputs(response.data)
      }
      setLoading(false)
    })
  }

  React.useEffect(()=>{
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  },[])

  const onSubmit=(e)=>{
    e.preventDefault()
    context.post(uri(location.pathname),{  ...inputs },false,true).then((response)=>{
      if (response && response.data && response.data && response.data.id) {
        setInputs(response.data)
        window.history.go(-1)
      }
    })
  }

  return    <>
              {loading?   <Loading height={'60vh'}/>:false}
              {!loading?  <Grid sx={{ mt: 1 }} container justifyContent="center">
                            <Grid item xs={12} md={11}>
                              <form onSubmit={onSubmit}>
                                <Paper sx={{ p: 2, }} elevation={4}>
                                  <Grid container spacing={0}>
                                    <Grid item xs={12} md={3}>
                                      <Input  margin="normal"
                                              required
                                              type="text"
                                              defaultValue={inputs.title}
                                              label="Título"
                                              name="title"
                                              onChange={set}
                                              />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <Input  margin="normal"
                                              required
                                              type="text"
                                              defaultValue={inputs.domain}
                                              label="Dominio"
                                              name="domain"
                                              onChange={set}
                                              />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <Input  margin="normal"
                                              required
                                              type="email"
                                              defaultValue={inputs.email}
                                              label="Email"
                                              name="email"
                                              onChange={set}
                                              />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <Input  margin="normal"
                                              required
                                              type="text"
                                              defaultValue={inputs.phone}
                                              label="Teléfono o celular"
                                              name="phone"
                                              onChange={set}
                                              />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Input  margin="normal"
                                              required
                                              type="text"
                                              maxLength={155}
                                              defaultValue={inputs.description}
                                              label="Descripción"
                                              name="description"
                                              onChange={set}
                                              />
                                    </Grid>
                                    <Grid  item xs={12}>
                                      <Button variant="contained"
                                              type="submit"
                                              sx={{ ml: 1 }}>Guardar</Button>
                                      <Button type="button"
                                              onClick={()=>window.history.go(-1)}
                                              variant="outlined"
                                              sx={{ ml: 1 }}>
                                        Cancelar
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </form>
                            </Grid>
                          </Grid>:false}
            </>
}
export default App
