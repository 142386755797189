import Grid from '@mui/material/Grid';
import Table from '../common/Table';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Button   from '@mui/material/Button';
import { NavLink } from "react-router-dom";

const title =   "Finca"

const td  = [
              {
                label:"Finca",
                value:"name",
                align:"left"
              },
              {
                label:"Pais",
                value:"pais",
                align:"left"
              },
              {
                label:"Departamento",
                value:"departamento",
                align:"left"
              },
              {
                label:"Municipio",
                value:"municipio",
                align:"left"
              },
              {
                label:"Ubicación",
                value:"ubicacion",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["edit","destroy"]
              },
            ]

const App = ({usePrivileges,endpoint})=>{
  return  <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12}  sx={{
                                            mt:{ xs: 1, md: 0 },
                                            mb: { xs: 1, md: 0 },
                                            p:{ xs: 1, md: 0 },
                                          }}>
                  <Table prefix="admin" component={<Button variant="contained" startIcon={<ControlPointIcon/>} size="small" fullWidth sx={{ mb: { xs: 1, md: 0 },  }} component={NavLink} to={"0"}>{title}</Button>} td={td} endpoint={endpoint?endpoint:false}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
