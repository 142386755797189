import React from 'react';
import Grid from '@mui/material/Grid';
//import Box from '@mui/material/Box';
//import TextField from '@mui/material/TextField';
//import Button from '@mui/material/Button';
import StateContext from '../../helpers/ContextState';
import Typography from '@mui/material/Typography';
import Loading from '../common/Loading01';
import { useLocation } from "react-router-dom";
//import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from "react-router-dom";
import CardItems from '../common/CardItems'
import Pagination from '@mui/material/Pagination';

let getInit         =   false
const queryString   =   window.location.search;
const urlParams     =   new URLSearchParams(queryString);
let current         =   (parseInt(urlParams.get('page')))?parseInt(urlParams.get('page')):1

const App=()=>{

  const navigate              =   useNavigate();
  const location              =   useLocation();
  const context               =   React.useContext(StateContext);
  const [data,setData]        =   React.useState({})
  const [loading,setLoading]  =   React.useState(true)
  const [pages, setPages]     =   React.useState([]);

  getInit=(mounted,filter)=>{

    if (filter || filter==='') {
      setPages([])
    }

    setData({})
    setLoading(true)

    context.get("api"+location.pathname+location.search,{...context.user},false,false).then((response)=>{
      if (response.data) {
        setData(response.data)
        setLoading(false)
      }
      if (response.data && response.data.links && pages.length===0) {
        setPages(response.data.links)
      }
    })
  }

  React.useEffect(()=>{
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  },[])

  const onChange=(event,page)=>{
    location.search="?page="+page
    navigate(location.pathname+"?page="+page)
    getInit(true)
  }

  return  <>
            <Grid container justifyContent="center" spacing={2} sx={{mb:0}} className="bg-white">
              {
                !loading?<>
                  <Grid item xs={11} sx={{ mt: {
                                                  xs:10,
                                                  md:14
                                                },
                                          }} >

                      <Typography component="b" className="text-primary h1">
                        Comunidad {process.env.REACT_APP_NAME}
                      </Typography>



                      {data && data.data && data.data.length>0?<>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                          {data.data.map((row,key)=>{
                            return  <Grid item xs={12} md={4} key={key} >
                                      <Grid sx={{m:1,p:2}}>
                                        <CardItems data={row}/>
                                      </Grid>
                                    </Grid>
                          })}
                        </Grid>
                      </>:false}
                  </Grid>
                </>:<>
                  <Loading/>
                </>
              }
              <Grid item xs={11}>
                <Pagination defaultPage={current}
                            onChange={onChange}
                            count={(pages.length===3?pages.length-2:pages.length-2)}
                            shape="rounded"
                            color="secondary"
                            siblingCount={1}
                            boundaryCount={1}/>
              </Grid>
            </Grid>

          </>
}
export default App;
