import React from 'react';
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
const App=({data,xs,md})=>{
  return  <Grid container justifyContent="center">
            <Grid item xs={12} className="bg-gray">
              <Grid container justifyContent="center">
                <Grid item xs={12} md={5} className="bg-gray" sx={{pt:5,pb:5,pr:4}}>
                  <Typography component="h1" className="text-white cta-title" sx={{mb:2}}>¿Por qué Click Conexion?</Typography>
                  <Typography component="p" className="text-white" sx={{mb:2}}>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
                  </Typography>
                  <Typography component="p" className="text-white" sx={{mb:2}}>
                    Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} className="bg-big-black" sx={{pt:5,pb:5}}>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
