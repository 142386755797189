import Grid from '@mui/material/Grid';
import Table from '../common/Table';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Button   from '@mui/material/Button';
import { NavLink } from "react-router-dom";

const title =   "Contactos"

const td  = [
              {
                label:"Departamento",
                value:"departament",
                align:"left"
              },
              {
                label:"Celular",
                value:"phone",
                align:"left"
              },
              {
                label:"Email",
                value:"email",
                align:"left"
              },
              {
                label:"Acción",
                align:"center",
                items:["edit","destroy"]
              },
            ]

const App = ({usePrivileges,endpoint})=>{
  return  <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12}  sx={{
                                            mt:{ xs: 1, md: 0 },
                                            mb: { xs: 1, md: 0 },
                                            p:{ xs: 1, md: 0 },
                                          }}>
                  <Table component={<Button variant="outlined" startIcon={<ControlPointIcon/>} size="small" fullWidth sx={{ mb: { xs: 1, md: 0 },  }} component={NavLink} to={"0"}>Agregar {title}</Button>} td={td} endpoint={endpoint?endpoint:false}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
}
export default App
